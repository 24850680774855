import classNames from 'classnames';
import { FieldProps, useField, useFormikContext } from 'formik';
import * as React from 'react';

import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import inputContainerStyles from '@Components/form/inputs/InputContainer/InputContainer.scss';
import Icon from '@Components/Icon';
import Typography from '@Components/Typography';
import { Messages } from '@Config/messages';

//import { hasFieldError } from '@Utils/form';
import InputContainer from '../InputContainer';

import styles from './MultiRadioButton.scss';

export type OptionItem = {
  label: string;
  value: any;
  infoMessage?: string;
  infoTitle?: string;
};

export interface MultiRadioButtonProps extends FieldProps {
  options: OptionItem[];
  label: Messages;
  disabled?: boolean;
  required?: boolean;
  mSize?: boolean;
}

const MultiRadioButton: React.FunctionComponent<MultiRadioButtonProps> = ({
  label,
  options,
  form,
  field,
  // disabled,
  required = false,
  mSize = false,
}) => {
  const [input, meta] = useField(field);
  // const { name, value } = input;
  // const hasError = hasFieldError(meta, form);
  const { setFieldValue } = useFormikContext();

  const handleOnChange = (event: any) => {
    setFieldValue(input.name, event.target.value);
  };

  return (
    <InputContainer
      form={form}
      label={label}
      meta={meta}
      showErrorIcon={true}
      containerClass={classNames(inputContainerStyles.container)}
      required={required}
    >
      <div className={inputContainerStyles.relative}>
        {options.map((item) => {
          return (
            <label key={item.value} className={classNames(styles.checkbox)}>
              <input
                type="radio"
                name={input.name}
                value={item.value}
                checked={field.value ? field.value === item.value : false}
                onChange={handleOnChange}
              />
              <span
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13) {
                    const buttonValue = document.getElementById(item.value)?.getAttribute('data-radio-value');
                    setFieldValue(input.name, buttonValue);
                  }
                }}
                id={item.value}
                data-radio-value={item.value}
                className={classNames(styles.inputBox)}
                //eslint-disable-next-line
                tabIndex={0}
              />
              <div className={styles.labelSpacing}>
                {item.label && <Typography msg={item.label} tag="div" size={mSize ? 'm' : 'l'} />}
              </div>
              <div className={styles.labelSpacing}>
                {item.infoMessage && (
                  <Tooltip position={TooltipPosition.top} message={item.infoMessage} title={item.infoTitle}>
                    <Icon icon="info" />
                  </Tooltip>
                )}
              </div>
            </label>
          );
        })}
      </div>
    </InputContainer>
  );
};

export default MultiRadioButton;
