import * as React from 'react';
import { useMediaQuery } from 'react-responsive';

import CompanyStepper from '@Components/application/CompanyStepper';
import Row from '@Components/layout/Row';
import { VerticalStepperNew } from '@Components/VerticalStepperNew';
import { ReactResponsiveQueries } from '@Config/constants';
import {
  DeathCaseSubpages,
  DeathCaseSubpages2,
  DeathCaseSubpages3,
  DeathCaseSubpagesNew,
} from '@Routes/deathCase/DeathCasePage/deathCaseSubpages';
import { LifeCaseSubpages } from '@Routes/lifeCase/LifeCasePage/lifeCaseSubpages';
import { LifeEventSubPages } from '@Routes/lifeEvent/LifeEventPage/LifeEventSubpages';
import { CompanyProfileSubpages } from '@Routes/provider/CRA/craStepsSubPages';

import styles from './LeftSidebar.scss';

export interface LeftSidebarProps {
  activeStepIdx: number;
  subpages:
    | typeof DeathCaseSubpages
    | typeof DeathCaseSubpagesNew
    | typeof LifeCaseSubpages
    | typeof CompanyProfileSubpages
    | typeof LifeEventSubPages
    | typeof DeathCaseSubpages2
    | typeof DeathCaseSubpages3;
  title: string;
  id?: string;
  isLifeCase?: boolean;
  missing?: boolean;
  isLifeEvent?: boolean;
  isCRA?: boolean;
  isDeathCase?: boolean;
}

const LeftSidebar: React.FunctionComponent<LeftSidebarProps> = ({
  activeStepIdx,
  subpages,
  id,
  isLifeCase,
  missing,
  isCRA = false,
  isLifeEvent = false,
  isDeathCase,
}) => {
  // const history = useHistory();
  // const pathName = history.location.pathname;
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  // const isCreateForm = pathName.includes(CaseTags.deathCaseCreated) || pathName.includes(CaseTags.lifeCaseCreated);

  return (
    <div className={styles.sidebarContainer}>
      <Row className={styles.title} alignCenter wrap>
        <div>
          <img
            width={isTablet ? '128' : '173'}
            height="37"
            src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
            alt=""
          />
        </div>
      </Row>

      {!isCRA && (
        <VerticalStepperNew
          steps={Object.values(subpages)}
          activeStepIdx={activeStepIdx}
          id={id}
          isLifeCase={isLifeCase}
          missing={missing}
          isLifeEvent={isLifeEvent}
          isDeathCase={isDeathCase}
        />
      )}

      {isCRA && <CompanyStepper steps={Object.values(subpages)} activeStepIdx={activeStepIdx} id={id} />}
    </div>
  );
};

export default LeftSidebar;
