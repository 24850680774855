import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Tour from 'reactour';

import Button from '@Components/Button';
import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { PaperWidths } from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, LocalStorage, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { mutationAddOrUpdateUserServiceProviders } from '@Graphql/dc/mutations';
import {
  AddOrUpdateUserServiceProvidersMutation,
  AddOrUpdateUserServiceProvidersMutationVariables,
  CaseServiceProviderStatus,
  DeathCaseFragment,
  LifeCaseFragment,
  UserServiceProviderNotificationNode,
} from '@Graphql/graphqlTypes.generated';
import { queryUserServiceProviderNotifications } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { StatusColor, StatusTitleColors } from '@Routes/shared/NotificationStatusPanel/NotificationStatusPanel';
import notifyStyles from '@Routes/shared/NotificationStatusPanel/NotificationStatusPanel.scss';
import { showModal } from '@Store/app/app.actions';
import { normaliseGqlError } from '@Utils/form';
import { convertStatus, decideStatus } from '@Utils/helpers';
import { setToLocalStorage } from '@Utils/localStorage';
import { draftHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import Tooltip, { TooltipPosition } from '../Tooltip';
import TourContent from '../TourContent/TourContent';

import styles from './NotificationCard.scss';

export interface DraftNotificationCardProps {
  isLifeCase?: boolean;
  caseData?: DeathCaseFragment | LifeCaseFragment;
  initialBackground?: string;
  cardId?: string;
  isMissingInfo?: boolean;
  showTourCallback: () => void;
  isTourOpen: boolean;
  goToTab?: () => void;
  draftNotification: UserServiceProviderNotificationNode;
}

const DraftNotificationCard: React.FunctionComponent<DraftNotificationCardProps> = ({
  isLifeCase = false,
  caseData,
  initialBackground,
  cardId,
  showTourCallback,
  isTourOpen,
  draftNotification,
}) => {
  const dispatch = useDispatch();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const t = useTranslations();

  const [deleteUserSp] = useMutation<
    AddOrUpdateUserServiceProvidersMutation,
    AddOrUpdateUserServiceProvidersMutationVariables
  >(mutationAddOrUpdateUserServiceProviders, {
    refetchQueries: [getOperationName(queryUserServiceProviderNotifications) || ''],
    onCompleted: () => {},
    onError: (error) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const deleteHandler = () => {
    return dispatch(
      showModal({
        type: ModalTypes.confirmModal,
        params: {
          data: {
            onClose: () =>
              deleteUserSp({
                variables: {
                  input: { serviceProviderIds: [draftNotification.userServiceProvider.serviceProvider.id] },
                },
              }),
            secondButtonText: t(Messages.buttonGoBack),
            buttonText: t(Messages.labelYes),
          },
          title: t(Messages.modalRemoveDCSPTitle),
          subtitle: t(Messages.modalRemoveDCSPSubtitle),
          modalSize: PaperWidths.m,
        },
      })
    );
  };

  const { serviceProvider } = draftNotification.userServiceProvider;

  const currentStatus = decideStatus(convertStatus(CaseServiceProviderStatus.ReadyToSend));

  const hideStatusPanel = () => {
    return true;
  };

  const leftSection = () => {
    return isTablet ? 4 : 5;
  };

  const rightSection = () => {
    return isTablet ? 8 : 7;
  };

  const alignViewDetailsAtBottom = () => {
    return false;
  };

  const mainSection = (
    <Col alignCenter size={leftSection()} className={styles.nameDetails}>
      <Row className={styles.lineHeight} column>
        <Col className={styles.draftSplitSpNameAndStatusPanel}>
          <Typography className={styles.spName} msg={serviceProvider.name} tag="span" size="m" bolder />

          <Tooltip
            title={t('tooltip_title_notification_unsent' as Messages)}
            message={t('tooltip_desc_notification_draft' as Messages)}
            position={TooltipPosition.top}
          >
            <Icon className={styles.infoIcon} icon="info" />
          </Tooltip>

          {hideStatusPanel() && (
            <span
              className={classNames(styles.statusPanel, {
                [notifyStyles[`${currentStatus.backgroundColor}` as StatusColor]]: currentStatus.backgroundColor,
              })}
            >
              {/* <Icon icon={currentStatus.icon} size={IconSizes.ss} /> */}
              <span
                className={classNames(styles.statusTitle, {
                  [notifyStyles[`${currentStatus.statusTitleColor}` as StatusTitleColors]]:
                    currentStatus.statusTitleColor,
                })}
              />
            </span>
          )}
        </Col>
      </Row>
    </Col>
  );

  const widgetUserSteps = [
    {
      selector: '#widgetFirstNotification',
      content: (
        <TourContent content="This is the notification that you just sent, click it to check the status of your notification" />
      ),
    },
  ];

  const closeActiveTour = () => {
    showTourCallback();
    setToLocalStorage(LocalStorage.widgetUserNotification, true);
  };

  return (
    <>
      <Row id={cardId} constant={!isMobile} className={classNames(styles.bottomSpacing)} size={12}>
        <Col
          tabindex={0}
          constant={!isMobile}
          size={11}
          className={classNames(styles.container, styles.cardClickable)}
          onClick={() =>
            draftHandler(
              t,
              caseData ? caseData.id : '',
              dispatch,
              isLifeCase,
              serviceProvider.id,
              draftNotification.userServiceProvider.id,
              true
            )
          }
          onKeyDown={(e: any) => {
            if (e.keyCode === EnterKeyCode) {
              draftHandler(
                t,
                caseData ? caseData.id : '',
                dispatch,
                isLifeCase,
                serviceProvider.id,
                draftNotification.userServiceProvider.id,
                true
              );
            }
          }}
        >
          <Row constant={!isMobile} size={12}>
            <div className={styles.combined}>
              {serviceProvider.logo ? (
                <Col className={styles.logoWrapper}>
                  <img src={serviceProvider.logo} alt="" />
                </Col>
              ) : (
                <div className={styles.initialLogoContainer} style={{ backgroundColor: initialBackground }}>
                  <span>{serviceProvider.name.charAt(0).toUpperCase()}</span>
                </div>
              )}
              {isMobile ? mainSection : null}
            </div>
            {isMobile ? null : mainSection}

            <Col alignBottom={alignViewDetailsAtBottom()} size={rightSection()}>
              <Row
                constant
                className={styles.readyToSendActions}
                justifyEnd={!isMobile}
                justifyBetween={isMobile}
                // justifyCenter={!isMobile}
                alignCenter={!isMobile}
                size={12}
              >
                <div
                  /* eslint-disable-next-line */
                  tabIndex={0}
                  className={styles.editLink}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    draftHandler(
                      t,
                      caseData ? caseData.id : '',
                      dispatch,
                      isLifeCase,
                      serviceProvider.id,
                      draftNotification.userServiceProvider.id,
                      true
                    );
                  }}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === EnterKeyCode) {
                      e.stopPropagation();
                      draftHandler(
                        t,
                        caseData ? caseData.id : '',
                        dispatch,
                        isLifeCase,
                        serviceProvider.id,
                        draftNotification.userServiceProvider.id,
                        true
                      );
                    }
                  }}
                >
                  {t('link_add_details' as Messages)}
                </div>

                {!isMobile && <Col size={1} />}

                {isMobile && (
                  <div
                    //eslint-disable-next-line
                    tabIndex={0}
                    onKeyDown={(e: any) => {
                      if (e.keyCode === EnterKeyCode) {
                        deleteHandler();
                      }
                    }}
                    onClick={() => deleteHandler()}
                  >
                    <Icon icon="trash" className={styles.handSymbol} />
                  </div>
                )}
              </Row>
            </Col>
          </Row>
        </Col>

        {!isMobile && (
          <Col constant={!isMobile} size={1} alignCenter textCenter>
            <div
              //eslint-disable-next-line
              tabIndex={0}
              onKeyDown={(e: any) => {
                if (e.keyCode === EnterKeyCode) {
                  deleteHandler();
                }
              }}
              onClick={() => deleteHandler()}
            >
              <Icon icon="trash" className={styles.handSymbol} />
            </div>
          </Col>
        )}
      </Row>
      <Tour
        steps={widgetUserSteps}
        showNumber={false}
        isOpen={isTourOpen}
        onRequestClose={closeActiveTour}
        showNavigationNumber={false}
        lastStepNextButton={<Button onClick={closeActiveTour} label="Finish" isFullWidth />}
      />
    </>
  );
};

export default DraftNotificationCard;
