import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';

import NewsLetters from '@Components/application/NewsLetters';
import Footer from '@Components/Footer';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner/LoadingSpinner';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { useBusinessPartnersQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import BeSpokeLoginForm from '../BeSpokeLoginPage/BeSpokeLoginForm';

import styles from './PersonalisedLoginPage.scss';

export interface PersonalisedLoginPageProps {}

const PersonalisedLoginPage: React.FunctionComponent<PersonalisedLoginPageProps> = () => {
  const t = useTranslations();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const isTabletBetweenDesktop = useMediaQuery({ query: ReactResponsiveQueries.TabletBetweenDesktop });
  const location = useLocation();

  const bpData = useBusinessPartnersQuery({});

  const searchParams = new URLSearchParams(location.search);
  const logo = searchParams.get('logo')?.trim() || '';
  const companyName = searchParams.get('company')?.trim() || '';

  const beSpokeadvantagePoints = [
    t('label_bespoke_advantage_6' as Messages),
    t('label_bespoke_advantage_1' as Messages),
    t('label_bespoke_advantage_2' as Messages),
    t('label_bespoke_advantage_3' as Messages),
    t('label_bespoke_advantage_4' as Messages),
    t('label_bespoke_advantage_5' as Messages),
  ];

  const glAdvantages = [
    'Securely & safely store account details on the Life Ledger platform',
    'Upload and save important documents ',
    'Give other people access to help you manage your accounts',
  ];

  const isDesktop = !isTablet && !isMobile && !isTabletBetweenDesktop;

  const partnerName = location.pathname.replace('/', '').split('/')[1].toLowerCase();

  const isGoldenLeaves = location.pathname === Links.glfp;

  const advantagePoints = isGoldenLeaves ? glAdvantages : beSpokeadvantagePoints;

  const welcomeMsg = (): string => {
    if (isGoldenLeaves) {
      return `Welcome to the Life Ledger & ${companyName} partnership`;
    }
    return t(Messages.pageInvite);
  };

  const goldevLeavesTitle = `Your ${companyName} funeral plan entitles you to a `;

  const iconAvailable = ['eastsussex'].includes(partnerName);

  if (bpData.loading) {
    return <LoadingSpinner />;
  }

  const shortBpData = bpData.data?.businessPartners.find(
    (bpEach: any) => bpEach.shortUrl.toLowerCase() === partnerName
  );

  //eslint-disable-next-line
  React.useEffect(() => {
    if (shortBpData?.widgetId) {
      const script = document.createElement('script');
      script.src = 'https://ll-ui-assets.s3.eu-west-2.amazonaws.com/widget-assets/business_partner/business.js';
      script.async = true;
      script.defer = true;

      const widgetContainer = document.getElementById('root');
      if (widgetContainer) {
        widgetContainer.appendChild(script);
      }

      return () => {
        if (widgetContainer) {
          widgetContainer.innerHTML = ''; // Remove dynamically added script
        }
      };
    }
    //eslint-disable-next-line
    return undefined; // Ensure all code paths return something
  }, [shortBpData]);

  const renderBpLogo = () => {
    return shortBpData?.showCompanyLogo && shortBpData?.logo ? (
      <div className={styles.logoPlacement}>
        <img
          className={classNames(styles.logo, { [styles.threeMobileWidth]: partnerName === 'three' })}
          src={shortBpData?.logo}
          alt=""
        />
      </div>
    ) : (
      <Typography msg={shortBpData?.name} color="mixedGray" tag="div" size="xl" />
    );
  };

  return (
    <>
      <section className={styles.headerLayout}>
        <a href="https://lifeledger.com/">
          <img
            width={!isTablet ? '228' : '161'}
            height={!isTablet ? '49' : '35'}
            src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
            alt=""
          />
        </a>
      </section>
      {!shortBpData?.widgetId ? (
        <>
          <section className={styles.mainContentLayout}>
            <Row size={12} className={styles.gapBetween}>
              {isDesktop && <Col size={1} />}
              <Col className={styles.infoContent} size={6}>
                <Typography
                  className={classNames(styles.titlesLineHeight, styles.mb8)}
                  family="palatino"
                  msg={welcomeMsg()}
                  tag="div"
                  size={isDesktop ? 'h1l' : 'xxxl'}
                />
                {!isGoldenLeaves && (
                  <div className={styles.closeFreeze}>
                    <Typography tag="div" html msg={t('label_bp_info' as Messages)} />
                  </div>
                )}

                {!isGoldenLeaves && !iconAvailable && (
                  <Row alignCenter={!isMobile} className={styles.recommendedContainer}>
                    <Typography msg={t('label_as_recommended_by' as Messages)} color="mixedGray" tag="div" size="xl" />
                    {renderBpLogo()}
                  </Row>
                )}
                <div className={isGoldenLeaves ? styles.flexBox : ''}>
                  {isGoldenLeaves && <img className={styles.logoPlacement} src={logo} alt="" />}

                  {isGoldenLeaves && (
                    <img
                      width="157"
                      height="58"
                      src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
                      alt=""
                    />
                  )}
                </div>
              </Col>

              {!isMobile && !isMobileBetweenTablet && (
                <Col size={6}>
                  <BeSpokeLoginForm
                    partnerName={shortBpData?.trackingCode}
                    isAbsolute={true}
                    isGlfp={isGoldenLeaves}
                    showBrand={shortBpData?.showCompanyLogo}
                  />
                </Col>
              )}
            </Row>
          </section>
          <section className={styles.bottomContainer}>
            {(isMobile || isMobileBetweenTablet) && (
              <div className={styles.formPlacement}>
                <BeSpokeLoginForm
                  isGlfp={isGoldenLeaves}
                  partnerName={shortBpData?.trackingCode}
                  showBrand={shortBpData?.showCompanyLogo}
                />
              </div>
            )}
            <Row className={classNames(isGoldenLeaves ? styles.mb276 : styles.mb100, styles.advDiv)}>
              {isDesktop && <Col size={1} />}

              <Col size={isTabletBetweenDesktop ? 6 : 0} className={styles.advantagesContainer}>
                {isGoldenLeaves && (
                  <div className={styles.advantageTitle}>
                    {goldevLeavesTitle}
                    <b>free Life Ledger End of Life Plan</b>
                  </div>
                )}
                {advantagePoints.map((point: any, idx: number) => (
                  <Row key={idx} constant className={styles.points} alignCenter>
                    <Icon className={styles.tickIcon} size={IconSizes.tick} icon="tick" />
                    <Typography msg={point} size="l" tag="div" />
                  </Row>
                ))}
              </Col>
            </Row>
            <div className={styles.pb100}>
              <NewsLetters containerClassName={styles.mb40} />
            </div>
          </section>
        </>
      ) : (
        <div id="root" widgetid={shortBpData?.widgetId} />
      )}

      <Footer />
    </>
  );
};

export default PersonalisedLoginPage;
