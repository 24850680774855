import { useMutation } from '@apollo/react-hooks';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';

import Button from '@Components/Button';
import { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import { PaperWidths } from '@Components/Paper';
import { AppVersion, LocalStorage, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  CaseServiceProviderStatisticsNode,
  CaseServiceProviderStatus,
  DeathCaseFragment,
  Permissions,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserAccountType,
  UserVerificationStatus,
  useUserServiceProviderNotificationsQuery,
} from '@Graphql/graphqlTypes.generated';
import { mutationUpdateUser } from '@Graphql/settings/mutations';
import { useTranslations } from '@Hooks/useTranslations';
import DCServicesColumn from '@Routes/deathCase/ReadyDCPage/ServicesPage/DCServicesColumn';
import { showModal } from '@Store/app/app.actions';
import { getUser } from '@Store/auth/auth.selector';
import { normaliseGqlError } from '@Utils/form';
import { getFromLocalStorage } from '@Utils/localStorage';
import { addProviderHandler } from '@Utils/modal';
import { notificationError } from '@Utils/notificationUtils';

import layoutStyles from '../ReadyDCPage.scss';

import styles from './ServicesPage.scss';

export interface ServicesPageProps {
  caseData: DeathCaseFragment;
  className?: string;
  isLifeCase?: boolean;
  goToTab?: () => void;
  notificationsCount: CaseServiceProviderStatisticsNode;
  notificationsNumber?: number;
}

const ServicesPage: React.FunctionComponent<ServicesPageProps> = ({
  caseData,
  isLifeCase = false,
  goToTab,
  notificationsCount,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const [isActiveTourOpen, setIsActiveTourOpen] = React.useState(false);
  const { dest } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkVersion = () => {
    if (user?.appVersion === AppVersion.second || user?.accountType === UserAccountType.Individual) {
      return AppVersion.second;
    }
    return AppVersion.first;
  };

  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(mutationUpdateUser, {
    onCompleted: () => {
      if (user?.addMore) {
        addProviderHandler(t, caseData.id, dispatch, false, AppVersion.second);
      }
    },
    onError: (error: any) => {
      dispatch(notificationError(normaliseGqlError(error.message)));
    },
  });

  const draftNotifications = useUserServiceProviderNotificationsQuery({
    variables: {
      isPrimaryIdentifier: true,
    },
    skip: user?.accountType !== UserAccountType.Individual,
    fetchPolicy: 'no-cache',
    partialRefetch: true,
  });

  React.useEffect(() => {
    const welcomeGuideHandler = () => {
      return dispatch(
        showModal({
          type: ModalTypes.deathCaseWelcomeModal,
          params: {
            data: {
              id: caseData.id,
              onClose: () => {
                if (user?.addMore) {
                  updateUser({ variables: { input: { id: user ? user?.id : '', isFirstLogin: false } } });
                } else {
                  setIsActiveTourOpen(true);
                }
              },
            },
            title: '',
            noPadding: true,
            modalSize: PaperWidths.m672,
          },
        })
      );
    };

    if (user?.isFirstLogin && user.appVersion === '2.0') {
      if (user.verificationStatus === UserVerificationStatus.Verified) welcomeGuideHandler();
      else {
        setIsActiveTourOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user, caseData, updateUser, dest]);

  if (draftNotifications.loading) {
    return <LoadingSpinner />;
  }

  const getReadyToSendId = () => {
    const countsData = notificationsCount;
    if (
      countsData?.READY_TO_SEND === 1 &&
      countsData.SENT === 0 &&
      countsData.IN_PROGRESS === 0 &&
      countsData.CONFIRMED === 0 &&
      countsData.COMPLETED_CLOSED === 0 &&
      countsData.CLOSED === 0 &&
      countsData.COMPLETED_BY_LL === 0
    ) {
      return user?.appVersion === '2.0' &&
        getFromLocalStorage(LocalStorage.widgetUserNotification) === null &&
        !user.addMore
        ? 'widgetFirstNotification'
        : 'firstNotificationReady';
    }
    return undefined;
  };

  const getSentId = () => {
    const countsData = notificationsCount;
    if (
      countsData?.SENT === 1 &&
      countsData.IN_PROGRESS === 0 &&
      countsData.CONFIRMED === 0 &&
      countsData.COMPLETED_CLOSED === 0 &&
      countsData.CLOSED === 0 &&
      countsData.COMPLETED_BY_LL === 0
    ) {
      return user?.appVersion === '2.0' &&
        getFromLocalStorage(LocalStorage.widgetUserNotification) === null &&
        !user.addMore
        ? 'widgetFirstNotification'
        : 'firstNotificationSent';
    }
    return undefined;
  };

  const showTourCallback = () => {
    setIsActiveTourOpen(false);
    updateUser({ variables: { input: { id: user ? user?.id : '', isFirstLogin: false } } });
  };

  const draftNotificationsData = draftNotifications.data?.userServiceProviderNotifications;

  return (
    <>
      {caseData.myPermission !== Permissions.ReadOnly && (
        <div className={styles.mobileCompanyBlockSpacing}>
          <Row
            size={isTablet ? 8 : 6}
            justifyCenter
            className={classNames(
              styles.addCompanyBlock,
              { [layoutStyles.rightSideGap]: !isLifeCase },
              layoutStyles.shortenWidth
            )}
          >
            <Button
              onClick={() => {
                addProviderHandler(t, caseData.id, dispatch, false, checkVersion(), caseData);
              }}
              icon="plus"
              iconSize={IconSizes.ss}
              constant
              label={t(Messages.buttonAddCompanyToNotify)}
              isFullWidth
            />
          </Row>
        </div>
      )}
      <div className={styles.bottomSpacing}>
        <div className={styles.readyToSendContainer}>
          <Col className={styles.res1400Max} size={isTablet ? 12 : 11}>
            <DCServicesColumn
              id={caseData.id}
              statuses={[CaseServiceProviderStatus.ReadyToSend]}
              title={t('label_drafts_section' as Messages)}
              subtitle={t('label_notification_status_brief_prefix_ready_to_send' as Messages)}
              emptyMessage={t('label_empty_notification_brief_prefix_ready_to_send' as Messages)}
              icon="attach"
              caseData={caseData}
              notificationId={getReadyToSendId()}
              showTourCallback={showTourCallback}
              isTourOpen={isActiveTourOpen}
              goToTab={goToTab}
              draftNotificationsData={draftNotificationsData}
            />
          </Col>
        </div>

        <Col className={styles.res1400MaxRest} size={isTablet ? 12 : 11}>
          <div className={classNames(styles.spacingTop, layoutStyles.rightSideGap)}>
            <DCServicesColumn
              id={caseData.id}
              statuses={[
                CaseServiceProviderStatus.InProgressAwaitingInformationFromNotifier,
                CaseServiceProviderStatus.InProgressAwaitingInformationFromSp,
                CaseServiceProviderStatus.Sent,
                CaseServiceProviderStatus.InProgress,
                CaseServiceProviderStatus.InProgressAutoPopulationFailed,
              ]}
              title={t(Messages.labelSent)}
              subtitle={t('label_notification_status_brief_prefix_sent' as Messages)}
              emptyMessage={t('label_empty_notification_brief_prefix_sent' as Messages)}
              icon="paper-plane"
              caseData={caseData}
              notificationId={getSentId()}
              showTourCallback={showTourCallback}
              isTourOpen={isActiveTourOpen}
              goToTab={goToTab}
            />
          </div>
        </Col>

        <Col className={styles.res1400MaxRest} size={isTablet ? 12 : 11}>
          <div className={classNames(styles.spacingTop, layoutStyles.rightSideGap)}>
            <DCServicesColumn
              id={caseData.id}
              statuses={[
                CaseServiceProviderStatus.Closed,
                CaseServiceProviderStatus.NotFound,
                CaseServiceProviderStatus.Confirmed,
                CaseServiceProviderStatus.CompletedByLl,
                CaseServiceProviderStatus.CompletedClosed,
                CaseServiceProviderStatus.NoActionRequired,
                CaseServiceProviderStatus.CompletedSwitched,
                CaseServiceProviderStatus.AlreadyRegistered,
              ]}
              title={t(Messages.labelCompleted)}
              subtitle={t('label_notification_status_brief_prefix_completed' as Messages)}
              emptyMessage={t('label_empty_notification_brief_prefix_completed' as Messages)}
              icon="doneAll"
              caseData={caseData}
              goToTab={goToTab}
            />
          </div>
        </Col>
      </div>
    </>
  );
};

export default ServicesPage;
