import classNames from 'classnames';
import React, { useEffect, useRef, Dispatch, SetStateAction } from 'react';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import Tooltip, { TooltipPosition } from '@Components/application/Tooltip';
import Icon from '@Components/Icon';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { noop } from '@Utils/helpers';

import styles from './Tabs.scss';

export interface TabItem {
  title: string;
  count?: number;
  disable?: boolean;
  showTooltip?: boolean;
  content?: { header: string; desc: string };
  showLock?: boolean;
}

export interface TabsProps {
  tabs: TabItem[];
  activeTab: number;
  setActiveTab: Dispatch<SetStateAction<number>>;
  className?: string;
  tabChangeEvent?: () => void;
  removeTabWidth?: boolean;
}

const Tabs: React.FunctionComponent<TabsProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  className,
  tabChangeEvent = noop,
  removeTabWidth = false,
}) => {
  const firstTabRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (firstTabRef.current) {
        firstTabRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 1000);
  }, []);

  const tabIndividualHtml = (tabItem: TabItem, idx: number) => {
    return (
      <li
        ref={idx === 0 ? firstTabRef : null}
        /* eslint-disable-next-line */
        tabIndex={0}
        id={tabItem.title.replace(' ', '_')}
        key={idx}
        className={classNames(removeTabWidth ? styles.tabItemNoWidth : styles.tabItem, {
          [styles.active]: tabItem === tabs[activeTab],
          [styles.notActive]: tabItem !== tabs[activeTab] && !tabItem.disable,
          [styles.w210]: tabItem.title === 'Messages & document upload',
          [styles.noCursor]: tabItem.disable,
        })}
        onKeyDown={(e: any) => {
          if (!tabItem.disable && e.keyCode === 13) {
            setActiveTab(idx);
          }
        }}
        onClick={() => {
          if (!tabItem.disable) {
            setActiveTab(idx);
            tabChangeEvent();
          }
        }}
      >
        <Row alignCenter>
          {tabItem.disable && tabItem.showLock && <Icon icon="lock" className={styles.lockIcon} />}
          <Typography
            msg={tabItem.title}
            html
            tag="span"
            size="l"
            bolder
            className={classNames({ [styles.disabledTab]: tabItem.disable })}
          />
        </Row>
        {typeof tabItem.count !== 'undefined' && (
          <span
            className={tabItem === tabs[activeTab] ? styles.countContainerSelected : styles.countContainerUnSelected}
          >
            {tabItem.count}
          </span>
        )}
      </li>
    );
  };

  return (
    <ul className={classNames(className, styles.tabsContainer)}>
      {tabs.map((tabItem, idx) => (
        <React.Fragment key={idx}>
          {(tabItem.disable && (
            <Tooltip
              position={TooltipPosition.top}
              title={tabItem.content?.header}
              message={tabItem.content?.desc || ''}
            >
              {tabIndividualHtml(tabItem, idx)}
            </Tooltip>
          )) ||
            (tabItem.showTooltip && (
              <Popover
                position={PopoverPosition.top}
                title={tabItem.content?.header}
                message={tabItem.content?.desc}
                showContent={tabItem.showTooltip}
              >
                {tabIndividualHtml(tabItem, idx)}
              </Popover>
            )) || <>{tabIndividualHtml(tabItem, idx)}</>}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default Tabs;
