import * as React from 'react';
import { useDispatch } from 'react-redux';

import ServiceProviderSteps from '@Components/application/ServiceProviderSteps';
import Col from '@Components/layout/Col';
import LoadingSpinner from '@Components/LoadingSpinner';
import SmartModalBody from '@Components/Modal/SmartModalBody';
import { NavigationProps } from '@Components/NavigationBlock';
import { PaperWidths } from '@Components/Paper';
import { ModalTypes } from '@Config/constants';
import { Messages } from '@Config/messages';
import { DeathCaseFragment, DeathCaseNode, useUserUpfrontServiceProvidersQuery } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { showModal } from '@Store/app/app.actions';
import { noop } from '@Utils/helpers';
import { LoadableItem } from '@Utils/types';

export interface NotificationsFormProps extends NavigationProps {
  containerClasses?: string;
}

export interface NotificationsFormStateProps {
  data: LoadableItem<DeathCaseNode>;
}

export enum NotificationActions {
  Skipped = 'SKIPPED',
  Sent = 'SENT',
  Draft = 'DRAFT',
  Default = 'DEFAULT',
}

interface NotificationsFormPrivateProps extends NotificationsFormProps, NotificationsFormStateProps {}

const NotificationsForm: React.FunctionComponent<NotificationsFormPrivateProps> = ({ data }) => {
  const t = useTranslations();
  const dispatch = useDispatch();

  const [stepIndex, setStepIndex] = React.useState(0);
  const [userServiceProviders, setUserServiceProviders] = React.useState<any[]>([]);

  const serviceProvidersData = useUserUpfrontServiceProvidersQuery({
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    if (serviceProvidersData.data?.userUpfrontServiceProviders) {
      setUserServiceProviders((prevSps) => {
        const newSps = serviceProvidersData.data?.userUpfrontServiceProviders.map((edge: any) => {
          const { name, id, logo, notificationAction } = edge.serviceProvider ?? {};

          // Preserve existing notificationAction if already set
          const existingSp = prevSps.find((sp) => sp.id === id);

          return {
            name,
            id,
            logo,
            notificationAction: existingSp
              ? existingSp.notificationAction
              : notificationAction ?? NotificationActions.Default,
            userSpId: edge.id,
          };
        });

        return newSps ?? prevSps; // Ensure it always returns an array
      });
    }
  }, [serviceProvidersData.data]); // Re-run effect only when data changes

  if (serviceProvidersData.loading) {
    return <LoadingSpinner />;
  }

  const currentServiceProvider = (userServiceProviders && userServiceProviders[stepIndex]) || null;

  const setNextSp = (stepAction: NotificationActions) => {
    setUserServiceProviders((prevSps) => {
      const updatedSps = [...prevSps];
      updatedSps[stepIndex] = {
        ...updatedSps[stepIndex],
        notificationAction: stepAction,
      };
      return updatedSps;
    });

    let nextStep = stepIndex + 1;
    while (
      nextStep < userServiceProviders.length &&
      userServiceProviders[nextStep].notificationAction === NotificationActions.Sent
    ) {
      //eslint-disable-next-line
      nextStep++;
    }

    if (nextStep < userServiceProviders.length) {
      setStepIndex(nextStep);
    } else {
      dispatch(
        showModal({
          type: ModalTypes.deathRegisterSuccessFormModal,
          params: {
            data: {
              caseId: data.item?.id,
              title: t('title_multi_notification_success' as Messages),
              bodyMsg: t('body_multi_notification_success' as Messages),
              firstButtonMsg: t('button_view_notifications' as Messages),
              makeCenter: true,
            },
            title: '',
            modalSize: PaperWidths.widthSuccess,
            showCloseIcon: false,
            disableOutsideClick: true,
          },
        })
      );
    }
  };

  return (
    <Col size={10}>
      {(userServiceProviders?.length ?? 0) > 1 && (
        <ServiceProviderSteps companies={userServiceProviders} currentStep={stepIndex} setStepIndex={setStepIndex} />
      )}
      {data && currentServiceProvider && (
        <>
          <SmartModalBody
            modalData={{
              buttonText: '',
              deathCaseData: data.item as DeathCaseFragment,
              id: data.item?.id || '',
              serviceProviderId: currentServiceProvider.id,
              secondButtonText: t('button_not_now_but_later' as Messages),
              setNextSp,
              userUpfrontSpId: currentServiceProvider.userSpId,
              isForm: true,
            }}
            closeModal={noop}
          />
        </>
      )}
    </Col>
  );
};

export default NotificationsForm;
