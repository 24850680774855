import classNames from 'classnames';
import { FieldProps, useField } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import InputContainer from '@Components/form/inputs/InputContainer';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import {
  ServiceProviderIdentifierType,
  useOrganisationsLazyQuery,
  useServiceProviderSearchLazyQuery,
} from '@Graphql/graphqlTypes.generated';
import { hasFieldError } from '@Utils/form';
import { useServiceProvider } from '@Utils/ServiceProviderContext';

import styles from '../TextInput/TextInput.scss';

import autoCompletestyles from './AutoComplete.scss';

export interface AutoCompleteProps {
  placeholder: string;
  label: string;
  required?: boolean;
  showIcon?: boolean;
  disabled?: boolean;
  showLabel?: boolean;
  className?: string;
  withInputIcon?: boolean;
  fullWidth?: boolean;
  onSelectClick: Function;
  isCompanyCard?: boolean;
  organisationControl?: boolean;
  doNotShow?: boolean;
  isSmartModal?: boolean;
}

interface AutoCompletePrivateProps extends AutoCompleteProps, FieldProps {}

const AutoComplete: React.FunctionComponent<AutoCompletePrivateProps> = (props) => {
  const {
    label,
    required,
    placeholder,
    form,
    withInputIcon,
    onSelectClick,
    fullWidth,
    isCompanyCard,
    organisationControl,
    showIcon,
    doNotShow,
    isSmartModal,
    ...restProps
  } = props;

  const [field, meta, helpers] = useField(props.field);
  const { setValue } = helpers;
  const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState('');
  const hasError = hasFieldError(meta, form);
  const dropdownRef = useRef<HTMLInputElement | null>(null);
  const {
    serviceProviders: serviceProvidersData,
    loading: serviceProvidersLoading,
    refetchServiceProviders,
  } = useServiceProvider();

  const [getProvidersData, providersValues] = useServiceProviderSearchLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    onCompleted: () => {
      const suggestions = providersValues.data?.serviceProviderSearch;
      if (suggestions && suggestions?.length > 0) {
        if (isCompanyCard) {
          //eslint-disable-next-line
          suggestions?.unshift({
            id: '1000',
            identifierType: ServiceProviderIdentifierType.Bank,
            logo: 'briefcase',
            name: 'The company is not in this list',
            webSite: '',
          });
        }
        setFilteredSuggestions(suggestions);
        setShowSuggestions(true);
      }
    },
  });

  const [getOrganisations, organisationalValues] = useOrganisationsLazyQuery({
    fetchPolicy: 'network-only',
    partialRefetch: true,
    onCompleted: () => {
      const suggestions = organisationalValues.data?.organisations;
      setFilteredSuggestions(suggestions || []);
      setShowSuggestions(!!(suggestions && suggestions?.length > 0));
    },
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInputVal = e.currentTarget.value;
    setUserInput(userInputVal);
    setValue(userInputVal);

    if (userInputVal.length > 0) {
      if (organisationControl) {
        getOrganisations({ variables: { search: userInputVal } });
      } else if (serviceProvidersLoading || (serviceProvidersData && serviceProvidersData.length === 0)) {
        // Call the server until the local cache is ready
        getProvidersData({ variables: { searchString: userInputVal } });
      } else if (serviceProvidersData && serviceProvidersData.length > 0) {
        const localSuggestions = serviceProvidersData
          .map((sp: any) => {
            const searchVal = userInputVal.toLowerCase().trim(); // Trim spaces
            const alternateNamesArray = sp.alternateNames ? sp.alternateNames.split(';') : [];
            const namesToCheck = [sp.name, ...alternateNamesArray];

            const startsWithMatch = namesToCheck.some((name: string) => name.toLowerCase().startsWith(searchVal));

            const includesMatch = namesToCheck.some((name: string) => name.toLowerCase().includes(searchVal));

            return { sp, startsWithMatch, includesMatch };
          })
          .filter(({ startsWithMatch, includesMatch }) => startsWithMatch || includesMatch)
          .sort((a, b) => {
            // Prioritize startsWith matches over includes matches
            if (a.startsWithMatch && !b.startsWithMatch) return -1;
            if (!a.startsWithMatch && b.startsWithMatch) return 1;
            return 0; // Maintain order if both have the same type of match
          })
          .map(({ sp }) => sp); // Extract the filtered and sorted results

        setFilteredSuggestions(localSuggestions);
        setShowSuggestions(localSuggestions.length > 0);
      } else if (!organisationControl && !serviceProvidersData) {
        refetchServiceProviders();
      }
    } else {
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onClick = (suggestion: any) => {
    setUserInput(!doNotShow ? suggestion.name : '');
    setValue(!doNotShow ? suggestion.name : '');
    setShowSuggestions(false);
    onSelectClick(organisationControl ? suggestion.organisationType : suggestion);
  };

  const renderLogo = (suggestion: any) => {
    if (suggestion.id === '1000') {
      return (
        <Col className={autoCompletestyles.briefCaseContainer}>
          <Icon icon="briefcase" />
        </Col>
      );
    }
    if (suggestion.logo) {
      return <img className={autoCompletestyles.logoWrapper} src={suggestion.logo} alt="" />;
    }
    return (
      <Col>
        <div className={autoCompletestyles.initialLogoContainer}>
          <span>{suggestion.name.charAt(0).toUpperCase()}</span>
        </div>
      </Col>
    );
  };

  const renderSuggestionsList = () => {
    if (filteredSuggestions.length > 0 && showSuggestions) {
      return (
        <ul
          className={classNames(autoCompletestyles.suggestions, {
            [autoCompletestyles.suggestionForCompany]: isCompanyCard,
          })}
        >
          {filteredSuggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              className={classNames({
                [autoCompletestyles.listHover]: true,
              })}
              onClick={() => onClick(suggestion)}
            >
              <Row constant className={autoCompletestyles.logoGap} alignCenter>
                {renderLogo(suggestion)}
                <Col>{suggestion.name}</Col>
              </Row>
            </li>
          ))}
        </ul>
      );
    }

    const validateLength = organisationControl ? 0 : 1;

    if (
      !providersValues.loading &&
      !organisationalValues.loading &&
      userInput.length > validateLength &&
      filteredSuggestions.length === 0
    ) {
      return (
        <div className={classNames(autoCompletestyles.suggestions, autoCompletestyles.noMatch)}>
          <span>No match found</span>
        </div>
      );
    }

    if (providersValues.loading || organisationalValues.loading) {
      return (
        <div className={autoCompletestyles.suggestions}>
          <LoadingSpinner />
        </div>
      );
    }

    return null;
  };

  const suggestionsListComponent = renderSuggestionsList();

  return (
    <InputContainer
      label={label}
      meta={meta}
      form={form}
      required={required}
      fullWidth={fullWidth}
      containerClass={styles.wrapper}
      showErrorIcon={true}
    >
      <div ref={dropdownRef}>
        <input
          {...field}
          {...restProps}
          placeholder={placeholder}
          type="text"
          autoComplete="off"
          onChange={onChange}
          value={userInput}
          autoFocus={isSmartModal}
          className={classNames(styles.input, {
            [styles.error]: hasError,
            [styles.withInputIcon]: withInputIcon,
            [autoCompletestyles.inputTextBox]: isSmartModal,
          })}
        />
        {suggestionsListComponent}
        {showIcon && (
          <Icon
            icon="search"
            className={classNames(autoCompletestyles.searchIcon, {
              [autoCompletestyles.searchIconSmart]: isSmartModal,
            })}
            size={isSmartModal ? IconSizes.ssm : IconSizes.s}
          />
        )}
      </div>
    </InputContainer>
  );
};

export default AutoComplete;
