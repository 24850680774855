import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import List from '@Components/List';
import Typography from '@Components/Typography';
import { ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserAccountType } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import { ReferralSource } from '@Routes/auth/RegisterPage/IndividualRegisterPage/IndividualRegisterForm/IndividualRegisterForm';

import styles from './Benefits.scss';

export interface BenefitsProps {
  accountType: UserAccountType;
}

const Benefits: React.FunctionComponent<BenefitsProps> = ({ accountType }) => {
  const t = useTranslations();
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const mobileIndividualBenefits: string[] = [
    t('label_mobile_benefits_1' as Messages),
    'Avoid the repetitive process of giving the same information to multiple service providers when  registering a death',
    'Access to powerful tools to send multiple death notifications with a single click',
    'World class customer service guiding you through the process',
  ];

  const location = useLocation();
  const [showIndividualBenefits, setShowIndividualBenefits] = React.useState(false);

  const [showProfessionalBenefits, setShowProfessionalBenefits] = React.useState(false);
  const searchParams = new URLSearchParams(location.search);
  const refSourceParam = searchParams.get('ref_src') || '';
  const ralParam = searchParams.get('case') || '';

  const isHospice = refSourceParam.toUpperCase() === ReferralSource.LifeHospice || ralParam === 'life';

  const mobileIsHospiceIndividualBenefits: string[] = [
    `${t('label_hospice_benefits_1' as Messages)}`,
    `${t('label_hospice_benefits_2' as Messages)}`,
    `${t('label_hospice_benefits_3' as Messages)}`,
    `${t('label_hospice_benefits_4' as Messages)}`,
    `${t('label_hospice_benefits_5' as Messages)}`,
  ];

  const individualForm = () => {
    return isMobile ? (
      <div className={styles.mobileIndividualContainer}>
        <Row constant justifyBetween>
          <Col size={3}>
            <Typography msg={t(Messages.labelWhySignUp)} tag="div" size="xl" />
          </Col>
          <Col className={styles.textRight} size={3} onClick={() => setShowIndividualBenefits(!showIndividualBenefits)}>
            <Icon icon={showIndividualBenefits ? 'chevronUp' : 'chevronDown'} />
          </Col>
        </Row>
        {showIndividualBenefits && (
          <Row>
            <List
              listItems={isHospice ? mobileIsHospiceIndividualBenefits : mobileIndividualBenefits}
              fontSize="l"
              isHelvetica={true}
              listSpacingClass={styles.listSpacing}
            />
          </Row>
        )}
      </div>
    ) : (
      <div className={styles.container}>
        <Row>
          <Typography msg={t(Messages.labelTheBenefits)} tag="div" size="xl" />
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick" />
          </Col>
          <Col size={8}>
            <Typography
              msg={isHospice ? t('label_hospice_benefits_1' as Messages) : t('label_individual_benefits_1' as Messages)}
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick" />
          </Col>
          <Col size={8}>
            <Typography
              msg={isHospice ? t('label_hospice_benefits_2' as Messages) : 'Fast, simple and secure'}
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick" />
          </Col>
          <Col size={8}>
            <Typography
              msg={
                isHospice
                  ? t('label_hospice_benefits_3' as Messages)
                  : 'Contact all of the businesses connected with the deceased from a single point'
              }
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick" />
          </Col>
          <Col size={8}>
            <Typography
              msg={
                isHospice
                  ? t('label_hospice_benefits_4' as Messages)
                  : 'Notify over 1000 UK companies ranging from banks and insurance to gas, water, telecoms and social media'
              }
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick" />
          </Col>
          <Col size={8}>
            <Typography
              msg={
                isHospice
                  ? t('label_hospice_benefits_5' as Messages)
                  : 'No need to have the same difficult conversation over and over with different people in different businesses'
              }
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
      </div>
    );
  };

  const professionalForm = () => {
    return isMobile ? (
      <div className={styles.profMobileContainer}>
        <Row constant justifyBetween>
          <Col size={1}>
            <Typography msg={t(Messages.labelWhySignUp)} tag="div" size="xl" />
          </Col>
          <Col
            className={styles.textRight}
            size={1}
            onClick={() => setShowProfessionalBenefits(!showProfessionalBenefits)}
          >
            <Icon icon={showProfessionalBenefits ? 'chevronUp' : 'chevronDown'} />
          </Col>
        </Row>
        {showProfessionalBenefits && (
          <>
            <Row constant className={styles.listItemSpacing}>
              <Col alignCenter className={styles.tickSpacing}>
                <Icon icon="tick-yellow" />
              </Col>
              <Col>
                <Typography
                  msg="Streamline and simplify your death notification and probate work"
                  tag="div"
                  size="l"
                  family="helvetica"
                />
              </Col>
            </Row>
            <Row constant className={styles.listItemSpacing}>
              <Col alignCenter className={styles.tickSpacing}>
                <Icon icon="tick-yellow" />
              </Col>
              <Col>
                <Typography
                  msg="Manage multiple cases and multiple clients simultaneously"
                  tag="div"
                  size="l"
                  family="helvetica"
                />
              </Col>
            </Row>
            <Row constant className={styles.listItemSpacing}>
              <Col alignCenter className={styles.tickSpacing}>
                <Icon icon="tick-yellow" />
              </Col>
              <Col>
                <Typography
                  msg="A simple dashboard that delivers business wide collaboration"
                  tag="div"
                  size="l"
                  family="helvetica"
                />
              </Col>
            </Row>
            <Row constant className={styles.listItemSpacing}>
              <Col alignCenter className={styles.tickSpacing}>
                <Icon icon="tick-yellow" />
              </Col>
              <Col>
                <Typography
                  msg="Directly connected to 100’s of banks and insurance providers, gas, water, telecoms and social media companies"
                  tag="div"
                  size="l"
                  family="helvetica"
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    ) : (
      <div className={styles.profContainer}>
        <Row>
          <Typography msg={t(Messages.labelTheBenefits)} tag="div" size="xl" />
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick-yellow" />
          </Col>
          <Col size={10}>
            <Typography
              msg="Streamline and simplify your death notification and probate work"
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick-yellow" />
          </Col>
          <Col size={10}>
            <Typography
              msg="Manage multiple cases and multiple clients simultaneously"
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick-yellow" />
          </Col>
          <Col size={10}>
            <Typography
              msg="A simple dashboard that delivers business wide collaboration"
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
        <Row className={styles.listItemSpacing}>
          <Col alignCenter textCenter size={1}>
            <Icon icon="tick-yellow" />
          </Col>
          <Col size={10}>
            <Typography
              msg="Directly connected to 100’s of banks and insurance providers, gas, water, telecoms and social media companies"
              tag="div"
              size="l"
              family="helvetica"
            />
          </Col>
        </Row>
      </div>
    );
  };

  const spForm = () => {
    return (
      <div className={styles.profContainer}>
        <Typography bold msg={t('title_life_ledger_invited' as Messages)} tag="div" size="xl" />
        <Typography msg={t('label_register_help_text' as Messages)} tag="div" size="s" />
      </div>
    );
  };

  const returnForm = () => {
    if (
      accountType.toUpperCase() === UserAccountType.Professional ||
      accountType.toUpperCase() === UserAccountType.Agent
    ) {
      return professionalForm();
    }
    if (accountType.toUpperCase() === UserAccountType.ServiceProvider) {
      return spForm();
    }
    return individualForm();
  };

  return returnForm();
};

export default Benefits;
