import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Icon, { IconSizes } from '@Components/Icon';
import Paper, { PaperWidths } from '@Components/Paper';
import PaperBody, { PaperBodyOverflow, PaperBodySpacing } from '@Components/PaperBody';
import PaperHeader from '@Components/PaperHeader';
import { ApplicationLanguages, ModalTypes, ReactResponsiveQueries } from '@Config/constants';
import IDValidationError from '@Routes/shared/IDValidationError';
import { ModalState } from '@Store/app/app.reducer';
import { getStripeApiPublicKey } from '@Store/app/app.selector';
import { addCollaborator, editCollaborator } from '@Store/collaborator/collaborator.actions';
import { fetchDC, removeDocuments, uploadDocuments } from '@Store/dc/dc.actions';
import { getCurrentLanguage } from '@Store/localization/localization.selector';
import { createCaseProvider } from '@Store/provider/provider.actions';
import { noop } from '@Utils/helpers';
import { notificationError } from '@Utils/notificationUtils';

import AddUser from './AddUser';
import AuthorityActionModalBody from './AuthorityActionModalBody/AuthorityActionModalBody';
import BasicServicePayment from './BasicServicePayment';
import CancelSubscriptionConfirmationModalBody from './CancelSubscriptionConfirmationModalBody';
import ChangeAddressModalBody from './ChangeAddressModalBody/ChangeAddressModalBody';
import CompanyMissingInfoModalBody from './CompanyMissingInfoModalBody';
import ConfirmModalBody from './ConfirmModalBody';
import CookieSettingsModalBody from './CookieSettingsModalBody';
import DeathCaseSuccessModalBody from './DeathCaseSuccessModalBody/DeathCaseSuccessModalBody';
import DeathCaseWelcomeModalBody from './DeathCaseWelcomeModalBody';
import DeathNotificationModalBody from './DeathNotificationModalBody';
import DiscoveryDescBody from './DiscoveryDescModalBody';
import DiscoveryModalBody from './DiscoveryModalBody';
import EditCollaboratorModalBody from './EditCollaboratorModalBody';
import EmptyModalBody from './EmptyModalBody';
import FileUploadModalBody from './FileUploadModalBody';
import GenericModalBody from './GenericModalBody';
import IdVerfificationFileUploadModalBody from './IdVerfificationFileUploadModalBody';
import InviteToCollaborateModalBody from './InviteToCollaborateModalBody';
import LifeCaseActionsModalBody from './LifeCaseActionsModalBody';
import PaymentDetailsModalBody from './LifeEventPaymentModalBody';
import LoadingModalBody from './LoadingModalBody/LoadingModalBody';
import LogoPreviewModalBody from './LogoPreviewModalBody';
import MobileDCActionsModalBody from './MobileDCActionsModalBody';
import MobileNotificationActionsModalBody from './MobileNotificationActionsModalBody';
import styles from './Modal.scss';
import NewProviderFormModalBody from './NewProviderFormModalBody';
import NewWelcomeModal from './NewWelcomeModal/NewWelcomeModal';
import NotificationStatusUpdateModalBody from './NotificationStatusUpdateModalBody/NotificationStatusUpdateModalBody';
import ObBanksNoSupport from './ObBanksNoSupport';
import ObNeverLeaveModalBody from './ObNeverLeaveModalBody';
import PaymentModalBody from './PaymentModalBody';
import PaymentModeModalBody from './PaymentModeModalBody';
import PaymentUpgradeModalBody from './PaymentUpgradeModalBody';
import ProviderFormModalBody from './ProviderFormModalBody';
import RegisterDeathCaseConfimModalBody from './RegisterDeathCaseConfimModalBody/RegisterDeathCaseConfimModalBody';
import RegisterLifeCaseModal from './RegisterLifeCaseModal/RegisterLifeCaseModal';
import RenewSubscriptionModalBody from './RenewSubscriptionModalBody/RenewSubscriptionModalBody';
import SaveChangesModalBody from './SaveChanges/SaveChangesModalBody';
import SlugBasedDataModalBody from './SlugBasedDataModalBody';
import SmartModalBody from './SmartModalBody/SmartModalBody';
import SomethingWrongModalBody from './SomethingWrongModalBody/SomethingWrongModalBody';
import TermsAndConditionsModalBody from './TermsAndConditionsModalBody';
import UserInputModalBody from './UserInputModalBody';
import WelcomeModalBody from './WelcomeModalBody/WelcomeModalBody';

export interface ModalParams<T = any> {
  data?: T;
  title?: string;
  subtitle?: string;
  modalSize?: PaperWidths;
  modalSpacing?: PaperBodySpacing;
  modalOverflow?: PaperBodyOverflow;
  fixedFormHeight?: boolean;
  isPaymentModal?: boolean;
  changeModalPosition?: boolean;
  isGrayPaper?: boolean;
  noPadding?: boolean;
  showCloseIcon?: boolean;
  disableOutsideClick?: boolean;
  isNotificationModal?: boolean;
  scrollBar?: boolean;
  isRenewModal?: boolean;
  onClose?: () => void;
}

// ToDo: Move other actions and styles from modal to specific modal body
export interface ModalDispatchProps {
  closeModal: () => void;
  createProviderRequest?: typeof createCaseProvider.started;
  notificationErrorRequest?: typeof notificationError;
  uploadDocumentsRequest?: typeof uploadDocuments.started;
  removeDocumentsRequest?: typeof removeDocuments.started;
  addCollaboratorRequest?: typeof addCollaborator.started;
  editCollaboratorRequest?: typeof editCollaborator.started;
  fetchDCRequest?: typeof fetchDC.started;
}

export interface ModalProviderProps<T = any> extends ModalDispatchProps {
  modalData: T;
  currentLanguage?: ApplicationLanguages;
  data?: any;
  extraProp?: boolean;
}

const Modal: React.FunctionComponent<ModalProviderProps<ModalState>> = ({ closeModal, modalData }) => {
  const {
    modalType,
    modalParams: {
      title,
      data,
      modalSize = PaperWidths.m,
      modalSpacing = 'none',
      modalOverflow,
      fixedFormHeight,
      isPaymentModal,
      changeModalPosition,
      isGrayPaper,
      noPadding = false,
      showCloseIcon = true,
      disableOutsideClick = false,
      isNotificationModal = false,
      scrollBar = false,
      isRenewModal = false,
      onClose = noop,
    },
    isVisible,
  } = modalData;

  const locale = useSelector(getCurrentLanguage);
  const publishableKey = useSelector(getStripeApiPublicKey);
  const stripePromise = loadStripe(publishableKey);
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });

  const modalBodyElements = {
    [ModalTypes.confirmModal]: ConfirmModalBody,
    [ModalTypes.providerFormModal]: ProviderFormModalBody,
    [ModalTypes.inviteToCollaborateFormModal]: InviteToCollaborateModalBody,
    [ModalTypes.editCollaboratorFormModal]: EditCollaboratorModalBody,
    [ModalTypes.fileUploadModal]: FileUploadModalBody,
    [ModalTypes.slugBasedDataModal]: SlugBasedDataModalBody,
    [ModalTypes.newProviderFormModal]: NewProviderFormModalBody,
    [ModalTypes.idVerfificationFileUploadModal]: IdVerfificationFileUploadModalBody,
    [ModalTypes.userInputFormModal]: UserInputModalBody,
    [ModalTypes.addUserModal]: AddUser,
    [ModalTypes.cookieSettingsFormModal]: CookieSettingsModalBody,
    [ModalTypes.registeredDeathCaseConfirmFormModal]: RegisterDeathCaseConfimModalBody,
    [ModalTypes.notificationStatusUpdateFormModal]: NotificationStatusUpdateModalBody,
    [ModalTypes.deathRegisterSuccessFormModal]: DeathCaseSuccessModalBody,
    [ModalTypes.paymentFormModal]: PaymentModalBody,
    [ModalTypes.discoveryDescModal]: DiscoveryDescBody,
    [ModalTypes.somethingWrongModal]: SomethingWrongModalBody,
    [ModalTypes.lifeCaseActionsModal]: LifeCaseActionsModalBody,
    [ModalTypes.mobileNotificationActionsModal]: MobileNotificationActionsModalBody,
    [ModalTypes.emptyContentModal]: EmptyModalBody,
    [ModalTypes.logoPreviewModal]: LogoPreviewModalBody,
    [ModalTypes.welcomeGuideModal]: WelcomeModalBody,
    [ModalTypes.deathCaseWelcomeModal]: DeathCaseWelcomeModalBody,
    [ModalTypes.authorityActionModal]: AuthorityActionModalBody,
    [ModalTypes.idValidationErrorModal]: IDValidationError,
    [ModalTypes.termsAndConditionsModal]: TermsAndConditionsModalBody,
    [ModalTypes.mobileDcCardActionsModal]: MobileDCActionsModalBody,
    [ModalTypes.paymentModeModal]: PaymentModeModalBody,
    [ModalTypes.discoveryModal]: DiscoveryModalBody,
    [ModalTypes.loadingModal]: LoadingModalBody,
    [ModalTypes.deathCaseNotificationModal]: DeathNotificationModalBody,
    [ModalTypes.saveChangesModal]: SaveChangesModalBody,
    [ModalTypes.companyMissingInfoModal]: CompanyMissingInfoModalBody,
    [ModalTypes.obNeverLeaveModal]: ObNeverLeaveModalBody,
    [ModalTypes.paymentUpgradeModal]: PaymentUpgradeModalBody,
    [ModalTypes.cancelSubscriptionConfirmModal]: CancelSubscriptionConfirmationModalBody,
    [ModalTypes.genericModal]: GenericModalBody,
    [ModalTypes.basicServicePayment]: BasicServicePayment,
    [ModalTypes.smartModal]: SmartModalBody,
    [ModalTypes.renewSubscription]: RenewSubscriptionModalBody,
    [ModalTypes.registerLCModal]: RegisterLifeCaseModal,
    [ModalTypes.newWelcomeModal]: NewWelcomeModal,
    [ModalTypes.obBanksNoSupport]: ObBanksNoSupport,
    [ModalTypes.changeAddressModal]: ChangeAddressModalBody,
    [ModalTypes.paymentDetailsModal]: PaymentDetailsModalBody,
  };

  React.useEffect(() => {
    if (isVisible) document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isVisible]);

  if (!isVisible || !modalType) {
    return null;
  }

  const ModalBody: React.ComponentType<any> = modalBodyElements[modalType];

  const getStyle = () => {
    return {
      top: changeModalPosition ? 'unset' : '0',
      bottom: changeModalPosition ? '60px' : '0',
    };
  };

  const bodyClass = () => {
    if (isPaymentModal) {
      return styles.paymentBody;
    }
    if (isNotificationModal) {
      return styles.notificationBody;
    }
    return styles.body;
  };

  const paperLayout = () => {
    if (noPadding) {
      return styles.modalNoPaddingPaper;
    }
    if (isNotificationModal) {
      return styles.modalFullScreenPaper;
    }
    return styles.modalPaper;
  };

  return (
    <Elements stripe={stripePromise} options={{ locale }}>
      <div
        className={classNames(styles.container, { [styles.scrollBar]: scrollBar })}
        style={getStyle()}
        /* eslint-disable-next-line */
        tabIndex={0}
      >
        <Paper
          width={modalSize}
          className={classNames(paperLayout(), {
            [styles.fixedFormHeight]: fixedFormHeight,
            [styles.grayPaper]: isGrayPaper,
            [styles.divWidth]: 0,
          })}
        >
          <div
            /* eslint-disable-next-line */
            tabIndex={0}
            id="emptyDiv"
            className={styles.emptyDiv}
          />

          {showCloseIcon && (
            <Icon
              icon="close-gray"
              onClick={() => {
                closeModal();
                if (isRenewModal) {
                  onClose();
                }
              }}
              className={classNames(isNotificationModal && isMobile ? styles.mobileCloseIcon : styles.closeIcon, {
                [styles.notificationCloseIcon]: isNotificationModal,
              })}
              size={IconSizes.sss}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  closeModal();
                  if (isRenewModal) {
                    onClose();
                  }
                }
              }}
            />
          )}

          {title && <PaperHeader title={title} className={styles.bottomSpacing} />}
          <PaperBody className={bodyClass()} spacing={modalSpacing} overflow={modalOverflow}>
            <ModalBody extraProp={false} closeModal={closeModal} modalData={data} />
          </PaperBody>
        </Paper>
        <div className={styles.backdrop} onClick={disableOutsideClick ? noop : closeModal} />
      </div>
    </Elements>
  );
};

export default Modal;
