import { useMutation } from '@apollo/react-hooks';
import { getOperationName } from 'apollo-link';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { isTablet } from 'react-device-detect';
import Dropzone, { FileRejection } from 'react-dropzone';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import Popover, { PopoverPosition } from '@Components/application/Popover';
import UploadDocument from '@Components/application/UploadDocument/UploadDocument';
import Button, { ButtonSizes, ButtonTypes } from '@Components/Button';
import CardTitle from '@Components/CardTitle';
import TextInput, { InputTypes } from '@Components/form/inputs/TextInput';
import FormError from '@Components/FormError';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Paper from '@Components/Paper';
import Typography from '@Components/Typography';
import { EnterKeyCode, fileUploadSettings, NodeDefiner, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  NotificationChatNode,
  CaseChatCreateMutation,
  CaseChatCreateMutationVariables,
  NotificationChatChatType,
  NotificationChatUserType,
  CaseServiceProviderFragment,
  NotificationDocumentNode,
  DeathCaseFragment,
  RestrictedDeathCaseNode,
  Permissions,
  UserNode,
  UserAccountType,
  UploadDocumentInNotificationMutation,
  UploadDocumentInNotificationMutationVariables,
  DocumentDocumentType,
} from '@Graphql/graphqlTypes.generated';
import { mutationCaseChatCreate, mutationUploadDocumentInNotification } from '@Graphql/providers/mutations';
import { queryCaseDocuments } from '@Graphql/providers/queries';
import { useTranslations } from '@Hooks/useTranslations';
import { currentDateTime, getDayMonth } from '@Utils/dates';
import { normaliseGqlError } from '@Utils/form';
import { noop, plotMessageUserName } from '@Utils/helpers';
import { notificationError, notificationSuccess } from '@Utils/notificationUtils';

import styles from './ChatMessagesForm.scss';

export interface ChatMessagesFormProps {
  csp: CaseServiceProviderFragment | undefined;
  chatType: NotificationChatChatType;
  caseChats: any[];
  uploadedFiles: NotificationDocumentNode[];
  deathCase: DeathCaseFragment | RestrictedDeathCaseNode;
  isCustomer?: boolean;
  user?: UserNode | null;
  msgParent?: string;
  setMsgCallback?: (comm: string) => void;
}

enum ChatMessagesFormFields {
  message = 'message',
}

const ChatMessagesForm: React.FunctionComponent<ChatMessagesFormProps> = ({
  csp,
  chatType,
  caseChats,
  uploadedFiles,
  deathCase,
  isCustomer,
  user,
  msgParent,
  setMsgCallback = noop,
}) => {
  const t = useTranslations();
  const dispatch = useDispatch();
  const [chatdata, setChatData] = useState<JSX.Element[]>([]);
  const convertedChats = caseChats || [];
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const [chats, setChats] = useState(convertedChats);
  const [draggedFile, setDraggedFile] = React.useState<File[]>([]);
  const [showModalOverlay, setShowModalOverlay] = React.useState(false);
  const [message, setMessage] = useState('');
  const [textValue, setTextValue] = useState(msgParent);
  const [loading, setLoading] = useState(false);

  const commentedByName = `${user?.firstName} ${user?.lastName}`;
  const isReadOnlyUser =
    deathCase.__typename === NodeDefiner.DeathCase && deathCase.myPermission === Permissions.ReadOnly;

  const userEmail = user ? user.email : '';

  const getUserType = () => {
    if (user?.isStaff) {
      return NotificationChatUserType.Lifeledger;
    }
    if (user?.accountType === UserAccountType.ServiceProvider) {
      return NotificationChatUserType.ServiceProvider;
    }
    return NotificationChatUserType.Individual;
  };

  const [createCaseChat] = useMutation<CaseChatCreateMutation, CaseChatCreateMutationVariables>(
    mutationCaseChatCreate,
    {
      onCompleted: () => {
        setChats([
          ...chats,
          {
            commentedAt: currentDateTime(),
            commentedByName,
            commentedBy: userEmail,
            chatText: message,
            userType: getUserType(),
          },
        ]);
        dispatch(notificationSuccess('Message sent'));
      },
      onError: (apiError) => {
        dispatch(notificationError(normaliseGqlError(apiError.message)));
        setLoading(false);
      },
    }
  );

  const [updateDocumentInChat] = useMutation<
    UploadDocumentInNotificationMutation,
    UploadDocumentInNotificationMutationVariables
  >(mutationUploadDocumentInNotification, {
    refetchQueries: [getOperationName(queryCaseDocuments) || ''],
    onCompleted: () => {
      dispatch(notificationSuccess(t(Messages.labelChatDocumentUploadSuccess)));
    },
    onError: (apiError) => {
      dispatch(notificationError(normaliseGqlError(apiError.message)));
    },
  });

  function usePrevious(value: any) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const previousCaseProviderId = usePrevious(csp?.id);

  const alwaysScrollToBottom = () => {
    const nextElement = document.querySelector('#chatWindowModal') as HTMLElement;
    if (nextElement !== null) {
      //eslint-disable-next-line
      return setTimeout(function () {
        nextElement.scrollTo({
          top: nextElement.scrollHeight,
          behavior: 'smooth',
        });
      }, 500);
    }
    return noop;
  };

  React.useEffect(() => {
    const nonBreakCaseChats = caseChats || [];

    const newChat = previousCaseProviderId !== csp?.id ? nonBreakCaseChats : chats;

    const newChatDataType = newChat.map((x: any) => ({
      userType: x.userType,
      commentedAt: x.commentedAt,
      chatSubject: x.chatSubject,
      chatText: x.chatText,
      commentedByName: x.commentedByName,
      file: '',
      fileName: '',
      documentType: '',
    }));

    const docUploadedUserType = (uploadedBy: string) => {
      return uploadedBy === NotificationChatUserType.Lifeledger
        ? NotificationChatUserType.Lifeledger
        : NotificationChatUserType.Individual;
    };

    const newChatDocumentDataType = uploadedFiles.map((dc: NotificationDocumentNode) => ({
      userType: dc.isAddedBySp ? NotificationChatUserType.ServiceProvider : docUploadedUserType(dc.uploadedBy),
      commentedAt: dc.addedAt,
      chatSubject: 'Uploaded a document',
      chatText: '',
      commentedByName: dc.uploadedBy === NotificationChatUserType.Lifeledger ? 'Life Ledger' : dc.addedByName,
      file: dc.file,
      fileName: dc.fileName,
      documentType: dc.documentType,
    }));

    const docsChat = [...newChatDataType, ...newChatDocumentDataType];

    const sortChatsDesc = docsChat.sort((a: any, b: any) => {
      const aDate = new Date(a.commentedAt);
      const bDate = new Date(b.commentedAt);
      if (aDate > bDate) return 1;
      if (aDate < bDate) return -1;
      return 0;
    });

    const getLogo = (chat: NotificationChatNode) => {
      if (chat.userType === NotificationChatUserType.ServiceProvider) {
        return csp?.serviceProvider.logo !== '' ? (
          <Col textCenter className={styles.logoWrapper}>
            <img src={csp?.serviceProvider ? csp.serviceProvider.logo : 'lifeledger-chat'} alt="" />
          </Col>
        ) : (
          <Col textCenter className={styles.personContainer}>
            <Typography
              className={styles.iconSpacing}
              msg={csp.serviceProvider.name.charAt(0).toUpperCase()}
              tag="span"
              color="white"
              bold
              size="lcx"
            />
          </Col>
        );
      }
      if (chat.userType === NotificationChatUserType.Lifeledger) {
        return (
          <Col textCenter className={styles.logoWrapper}>
            <Icon icon="lifeledger-chat" />
          </Col>
        );
      }
      return (
        <Col textCenter className={styles.personContainer}>
          <Typography
            className={styles.iconSpacing}
            msg={plotMessageUserName(chat)}
            tag="span"
            color="white"
            bold
            size="lcx"
          />
        </Col>
      );
    };

    const spName = csp?.serviceProvider ? csp.serviceProvider.name : '';

    const renderChatMessages = (chat: any) => {
      if (chat.file === '') {
        const splitMsgs = chat.chatText.split(/\r?\n/);
        return splitMsgs.map((m: string, idx: number) => {
          if (m !== '') {
            const htmlString = `<div class=${styles.chatMessageText}>${m}</div>`;
            return ReactHtmlParser(htmlString);
          }
          return <br key={idx} />;
        });
      }
      return (
        <Row constant size={12} justifyBetween={isTablet} className={styles.fileContainer}>
          <Col size={2} alignCenter>
            <div className={styles.flexBox}>
              <embed src={chat.file} width="64px" height="46px" type="application/pdf" />

              {isMobile && (
                <>
                  <Row className={styles.wrapText} column>
                    <Typography size="m" tag="div" msg={chat.fileName} />
                    <Typography
                      size="s"
                      tag="div"
                      className={styles.breakAll}
                      msg={t(`${Messages.documentTypePrefix}${chat.documentType}` as Messages)}
                      color="footerColor"
                    />
                  </Row>
                  <Row alignCenter constant>
                    <a className={styles.mt10} href={chat.file} target="_blank" rel="noopener noreferrer" download>
                      <Typography msg="View" color="newGray" tag="div" underline size="m" />
                    </a>
                  </Row>
                </>
              )}
            </div>
          </Col>

          {!isMobile && (
            <Col constant alignCenter size={9}>
              <Typography size="m" tag="div" msg={chat.fileName} />
              <Typography
                size="s"
                tag="div"
                msg={t(`${Messages.documentTypePrefix}${chat.documentType}` as Messages)}
                color="footerColor"
              />
            </Col>
          )}

          {!isMobile && (
            <Col constant className={styles.viewButton} alignCenter size={1}>
              <a href={chat.file} target="_blank" rel="noopener noreferrer" download>
                <Typography msg="View" color="newGray" tag="div" underline size="m" />
              </a>
            </Col>
          )}
        </Row>
      );
    };

    const uploads = sortChatsDesc.map((chat: any) => (
      <>
        <Row constant className={styles.bigBottomSpacing} size={12}>
          {getLogo(chat)}
          <Col className={styles.chatContainer} size={11}>
            <Row className={styles.chatMessageSpacing} column>
              <Col>
                <Typography
                  msg={chat.userType === NotificationChatUserType.ServiceProvider ? `${spName}` : chat.commentedByName}
                  tag="span"
                  size="s"
                  bold
                  className={styles.rightSpacing}
                />
                <Typography msg={getDayMonth(new Date(chat.commentedAt))} tag="span" size="s" color="footerColor" />
              </Col>
              {chat.chatSubject && (
                <Col className={styles.sendButtonSpacing}>
                  <Typography msg={t(chat.chatSubject as Messages)} tag="span" size="xs" bold />
                </Col>
              )}
              <Col>{renderChatMessages(chat)}</Col>
            </Row>
          </Col>
        </Row>
      </>
    ));

    setChatData(uploads);
    alwaysScrollToBottom();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseChats, chats, setChatData, setLoading, userEmail, csp, previousCaseProviderId, uploadedFiles]);

  const { maxUploadSize, supportedUploadFormats } = fileUploadSettings;

  const documentTypeHtml = (
    <UploadDocument
      csp={csp}
      deathCase={deathCase}
      uploadedFiles={uploadedFiles}
      closePopOver={() => setShowModalOverlay(false)}
      draggedFiles={draggedFile}
      resetDraggedFile={() => setDraggedFile([])}
      isCustomer={isCustomer}
    />
  );

  const handleChange = (event: any) => {
    setTextValue(event.currentTarget.value);
    setMsgCallback(event.currentTarget.value);
  };

  const MessageInputForm = () => (
    <Formik
      initialValues={{
        [ChatMessagesFormFields.message]: textValue || '',
      }}
      onSubmit={(values) => {
        setLoading(true);
        if (values.message.trim() !== '') {
          setMessage(values.message);
          createCaseChat({
            variables: {
              input: {
                comment: values.message,
                deathCaseProviderId: csp ? csp?.id : '',
                commentedBy: userEmail,
                commentedByName,
                chatType,
              },
            },
          });
          setTextValue('');
          setMsgCallback('');
        } else {
          dispatch(notificationError(t(Messages.msgEnterMessage)));
          setLoading(false);
        }
      }}
    >
      {({ status }) => (
        <>
          <Form>
            <div className={styles.messageBoxContainer}>
              <div className={isMobile ? styles.flexBox : ''}>
                <Dropzone
                  noClick={true}
                  maxSize={maxUploadSize}
                  onDrop={(files: File[]) => {
                    if (user?.accountType !== UserAccountType.ServiceProvider) {
                      setShowModalOverlay(true);
                      setDraggedFile(files);
                    } else {
                      updateDocumentInChat({
                        variables: {
                          input: {
                            deathCaseProviderId: csp ? csp?.id : '',
                            documentType: DocumentDocumentType.SpUploaded,
                            files,
                          },
                        },
                      });
                    }
                  }}
                  accept={supportedUploadFormats}
                  onDropRejected={(fileRejected: FileRejection[]) => {
                    if (fileRejected[0].errors[0].code === 'file-too-large') {
                      dispatch(notificationError(t(Messages.msgFileSizeLarge)));
                    } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
                      dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
                    }
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div style={{ width: '100%' }} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Field
                        name={ChatMessagesFormFields.message}
                        type={InputTypes.textArea}
                        component={TextInput}
                        className={styles.chatInputBox}
                        placeholder={`Message ${
                          isCustomer ? csp?.serviceProvider.name : 'notifier'
                        } or drag a document to upload`}
                        fullWidth
                        required
                        onBlurChange={handleChange}
                        increaseHeight={user?.isStaff ? '180px' : undefined}
                        disableResize={user?.isStaff}
                      />
                    </div>
                  )}
                </Dropzone>
                {isMobile && (
                  <Button
                    type={ButtonTypes.submit}
                    icon="paper-plane"
                    iconSize={IconSizes.s24}
                    size={ButtonSizes.ss}
                    loading={loading}
                  />
                )}
              </div>
              <div
                className={classNames(styles.buttonContainers, {
                  [styles.justifyEnd]: !isMobile,
                })}
              >
                {!isReadOnlyUser && user?.accountType !== UserAccountType.ServiceProvider && (
                  <Popover
                    showContent={showModalOverlay}
                    contentElement={documentTypeHtml}
                    position={PopoverPosition.top}
                    callBack={() => setShowModalOverlay(false)}
                  >
                    <div
                      onClick={() => setShowModalOverlay(!showModalOverlay)}
                      onKeyDown={(e: any) => {
                        if (e.keyCode === EnterKeyCode) {
                          setShowModalOverlay(!showModalOverlay);
                        }
                      }}
                      className={styles.flexDisplay}
                      //eslint-disable-next-line
                    tabIndex={0}
                    >
                      <Typography
                        className={styles.sendDocText}
                        tag="div"
                        size="m"
                        msg={t('label_three_way_upload_document' as Messages)}
                        color="black"
                      />
                      <Icon icon="upload" className={styles.uploadIcon} />
                    </div>
                  </Popover>
                )}
                {user?.accountType === UserAccountType.ServiceProvider && (
                  <Dropzone
                    maxSize={maxUploadSize}
                    onDrop={(files: File[]) =>
                      updateDocumentInChat({
                        variables: {
                          input: {
                            deathCaseProviderId: csp ? csp?.id : '',
                            documentType: DocumentDocumentType.SpUploaded,
                            files,
                          },
                        },
                      })
                    }
                    multiple={true}
                    accept={supportedUploadFormats}
                    onDropRejected={(fileRejected: FileRejection[]) => {
                      if (fileRejected[0].errors[0].code === 'file-too-large') {
                        dispatch(notificationError(t(Messages.msgFileSizeLarge)));
                      } else if (fileRejected[0].errors[0].code === 'file-invalid-type') {
                        dispatch(notificationError(t(Messages.msgFileFormatNotSupported)));
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        onClick={() => setShowModalOverlay(!showModalOverlay)}
                        onKeyDown={(e: any) => {
                          if (e.keyCode === EnterKeyCode) {
                            setShowModalOverlay(!showModalOverlay);
                          }
                        }}
                        className={styles.flexDisplay}
                        //eslint-disable-next-line
                      tabIndex={0}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <Typography
                          className={styles.sendDocText}
                          tag="div"
                          size="m"
                          msg={t('label_three_way_upload_document' as Messages)}
                          color="black"
                        />
                        <Icon icon="upload" className={styles.uploadIcon} />
                      </div>
                    )}
                  </Dropzone>
                )}
                <div onClick={() => setShowModalOverlay(false)} />
                {!isMobile && (
                  <>
                    {!isReadOnlyUser && <div className={styles.lineSep} />}
                    <Button
                      type={ButtonTypes.submit}
                      icon="paper-plane"
                      label={t(Messages.buttonSend)}
                      iconSize={IconSizes.s24}
                      constant
                      disabled={isReadOnlyUser}
                      loading={loading}
                    />
                  </>
                )}
              </div>
            </div>
            <FormError formError={status} />
          </Form>
        </>
      )}
    </Formik>
  );

  return (
    <div>
      <Paper bordered key={csp?.id} width="width-full">
        <Row constant justifyBetween className={styles.header}>
          {isCustomer ? (
            <CardTitle heading={`${t(Messages.titleChatRelatedNotification)} ${csp && csp.serviceProvider.name}`} />
          ) : (
            <CardTitle heading={`Chat with ${isCustomer ? csp?.serviceProvider.name : 'Notifier'}`} />
          )}

          {/* {isMobile && <Icon icon="expand" />} */}
        </Row>
        <div className={styles.internalSpacing}>
          {chatdata.length > 0 ? (
            <div id="chatWindowModal" className={styles.chatMessagesContainer}>
              <div>{chatdata}</div>
            </div>
          ) : (
            <div className={styles.emptyMessageContainer}>
              <Row constant justifyCenter className={styles.emptyMsgSpacing}>
                <Icon icon="message-square" />
              </Row>
              <Row className={styles.mtop20} size={12} justifyCenter>
                <Col size={3} />
                <Col size={7} textCenter>
                  <Typography size="m" tag="div" msg={t('info_to_type_new_message' as Messages)} color="footerColor" />
                </Col>
                <Col size={3} />
              </Row>
            </div>
          )}
        </div>

        <MessageInputForm />
      </Paper>
    </div>
  );
};

export default ChatMessagesForm;
