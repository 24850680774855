import * as React from 'react';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import { ServiceProviderDefn } from '@Routes/auth/RegisterPage/IndividualRegisterPage/IndividualRegisterForm/IndividualRegisterForm';

import styles from './OptionBoxes.scss';

export interface OptionBoxesProps {
  serviceProviders: ServiceProviderDefn[];
  onSelectClick: Function;
}

const OptionBoxes: React.FunctionComponent<OptionBoxesProps> = ({ serviceProviders, onSelectClick }) => {
  return (
    <Row className={styles.optionBoxesContainer}>
      {serviceProviders.map((sp: ServiceProviderDefn) => {
        return (
          <Col key={sp.name} className={styles.cardBox}>
            {sp.logo ? (
              <img className={styles.logoWrapper} src={sp.logo} alt="" />
            ) : (
              <div className={styles.initialLogoContainer}>
                <span>{sp.name.charAt(0).toUpperCase()}</span>
              </div>
            )}
            {sp.name}
            <Icon icon="close-gray" onClick={() => onSelectClick(sp.id)} className={styles.ml4} />
          </Col>
        );
      })}
    </Row>
  );
};

export default OptionBoxes;
