import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { generatePath, useHistory, useParams } from 'react-router';

import MiniFooter from '@Components/application/MiniFooter';
import MobileMenu from '@Components/application/MobileMenu';
import SideMenu from '@Components/application/SideMenu';
import { MenuItems } from '@Components/application/SideMenu/SideMenu';
import Breadcrumb from '@Components/Breadcrumb';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import LoadingSpinner from '@Components/LoadingSpinner';
import Tabs from '@Components/Tabs';
import Typography from '@Components/Typography';
import { AppVersion, EnterKeyCode, Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import {
  DeathCaseFragment,
  DeathCaseNode,
  Permissions,
  useDeathCaseQuery,
  useTotalNotificationsByStatusQuery,
  CaseServiceProviderStatisticsNode,
  UserAccountType,
  useUserServiceProviderNotificationsQuery,
} from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';
import ServicesPage from '@Routes/deathCase/ReadyDCPage/ServicesPage';
import SummaryInfo from '@Routes/deathCase/SummaryInfo';
import { SettingsSubpages } from '@Routes/settings/settingsSubpages';
import CollaboratorsPage from '@Routes/shared/CollaboratorsPage';
import DocumentsPage from '@Routes/shared/DocumentsPage';
import { getEnableTuCheck } from '@Store/app/app.selector';
import { getUser } from '@Store/auth/auth.selector';
import { chooseIcon, modifyMissingItems, topSection } from '@Utils/helpers';
import { deathCaseNotificationHandler } from '@Utils/modal';
import { LoadableItem } from '@Utils/types';

import { DeathCaseSubpagesNew } from '../DeathCasePage/deathCaseSubpages';

import styles from './ReadyDCPage.scss';

export interface ReadyDCPageProps {}

enum DeathCaseTabs {
  TabServices,
  TabDocuments,
  TabCollaborators,
  TabSummary,
}

const ReadyDCPage: React.FunctionComponent<ReadyDCPageProps> = () => {
  const t = useTranslations();
  const history = useHistory();
  const queryString = history.location.search;
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(history.location.search);
  const caseproviderid = searchParams.get('caseproviderid')?.trim() || '';
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const isMobile = useMediaQuery({ query: ReactResponsiveQueries.Mobile });
  const isRes875 = useMediaQuery({ query: ReactResponsiveQueries.Res1118 });
  const isMobileBetweenTablet = useMediaQuery({ query: ReactResponsiveQueries.MobileBetweenTablet });
  const [mobileShowSideMenu, setMobileShowSideMenu] = React.useState(false);
  const [showUserNotification, setShowUserNotification] = React.useState(false);
  const overlayEl = React.useRef() as React.MutableRefObject<HTMLDivElement>;
  const user = useSelector(getUser);
  const { id } = useParams();
  const [activeTab, setActiveTab] = React.useState(DeathCaseTabs.TabServices);
  const enableTuCheck = useSelector(getEnableTuCheck);

  const { data, loading } = useDeathCaseQuery({
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const notificationCounts = useTotalNotificationsByStatusQuery({
    fetchPolicy: 'cache-and-network',
  });

  const serviceProvidersData = useUserServiceProviderNotificationsQuery({
    fetchPolicy: 'network-only',
    skip: user?.accountType !== UserAccountType.Individual,
    variables: {
      deathCaseId: id,
    },
  });

  const singleItemReducerInitialState = {
    item: data?.deathCase as DeathCaseNode,
    isFetching: false,
  };

  React.useEffect(() => {
    if (caseproviderid !== '') {
      setShowUserNotification(true);
    } else {
      setShowUserNotification(false);
    }
  }, [data, caseproviderid, setShowUserNotification, dispatch, user]);

  const dcItems: LoadableItem<DeathCaseNode> = singleItemReducerInitialState;

  const deathCase = data?.deathCase;

  const shortCount = notificationCounts.data?.totalNotificationsByStatus;

  const countDraft = serviceProvidersData.data?.userServiceProviderNotifications?.length || 0;

  const sumNotifications = shortCount
    ? shortCount.READY_TO_SEND +
      shortCount?.SENT +
      shortCount?.IN_PROGRESS +
      shortCount.CONFIRMED +
      shortCount.COMPLETED_CLOSED +
      shortCount.COMPLETED_BY_LL +
      shortCount.CLOSED +
      shortCount.ALREADY_REGISTERED +
      shortCount.IN_PROGRESS_AUTO_POPULATION_FAILED +
      shortCount.IN_PROGRESS_AWAITING_INFORMATION_FROM_NOTIFIER +
      shortCount.IN_PROGRESS_AWAITING_INFORMATION_FROM_NOTIFIER +
      shortCount.IN_PROGRESS_AWAITING_INFORMATION_FROM_SP +
      shortCount.NOT_FOUND +
      shortCount.NO_ACTION_REQUIRED +
      countDraft
    : 0;

  const showMessage = sumNotifications === 0 && activeTab !== DeathCaseTabs.TabServices;

  const breadcrumbItems = [{ title: `Death notifications: ${deathCase?.firstName} ${deathCase?.lastName}` }];

  const missingItems =
    data &&
    modifyMissingItems(
      data.deathCase.missingInfo
        .filter((x: any) => x.value === false)
        .filter((y: any) => y.field !== 'user_verification')
    );

  const dcTabs = [
    {
      title: 'Notifications',
      showTooltip: showMessage,
      content: { header: t('title_send_notification' as Messages), desc: t('desc_send_notification' as Messages) },
    },
    { title: t(Messages.tabDocuments) },
    { title: t(Messages.tabCollaborators) },
    { title: t(Messages.tabSummary) },
  ];

  const chooseSubpage = (field: string) => {
    if (field.includes('name') || field.includes('date')) {
      return DeathCaseSubpagesNew.General;
    }
    if (field.includes('address') || field.includes('city') || field.includes('post')) {
      return DeathCaseSubpagesNew.GeneralStep2;
    }
    return DeathCaseSubpagesNew.Certificate;
  };

  const chooseLink = (item: any) => {
    if (item.includes('user_verification')) {
      return history.push(generatePath(`${Links.settings}?${queryString}`, { subpage: SettingsSubpages.IDValidation }));
    }
    return history.push(
      generatePath(`${Links.dcEdit}${queryString}`, { id: data?.deathCase.id, subpage: chooseSubpage(item) })
    );
  };

  const onHamburgerClick = () => {
    setMobileShowSideMenu(!mobileShowSideMenu);
  };

  const scroll = (scrollOffset: any) => {
    overlayEl.current.scrollLeft += scrollOffset;
  };

  const otherRightDivSize = isMobile ? 12 : 10;

  const goToTab = () => {
    setActiveTab(DeathCaseTabs.TabSummary);
    window.scrollTo(0, 0);
  };

  const getIdLabels = (item: string) => {
    if (item.toLowerCase() === 'user_verification') {
      return enableTuCheck
        ? t(`${Messages.missingItemPrefix}${item.toLowerCase()}` as Messages)
        : t(`${Messages.missingItemPrefix}user_document` as Messages);
    }
    return t(`${Messages.missingItemPrefix}${item.toLowerCase()}` as Messages);
  };

  return (
    <>
      <main className={styles.layoutContainer}>
        <div>
          {isMobile && !mobileShowSideMenu ? (
            <MobileMenu grayBackground={true} onHamburgerClick={onHamburgerClick} showHamBurger={true} />
          ) : (
            <SideMenu
              closeClick={() => setMobileShowSideMenu(!mobileShowSideMenu)}
              page={MenuItems.ViewDeathCase}
              caseId={id}
              showSideMenuItems={true}
            />
          )}
        </div>
        {!mobileShowSideMenu && (
          <div className={classNames(styles.rightSideContainer, styles.hideScroll)}>
            <Col>
              <div className={styles.readyDCHeader}>
                {deathCase?.firstName && (
                  <div className={styles.rightSideGap}>
                    <Breadcrumb breadcrumbItems={breadcrumbItems} />
                  </div>
                )}
                {!loading && !notificationCounts.loading && (
                  <Row className={styles.gap2} size={topSection(user, isRes875)}>
                    <Row size={6} column>
                      <Typography
                        msg="Registered death"
                        tag="h4"
                        size="xl"
                        className={classNames(styles.spaceUpAndDown, styles.rightSideGap)}
                      />
                      <Row
                        alignCenter
                        onClick={() => {
                          setActiveTab(DeathCaseTabs.TabSummary);
                          scroll(250);
                        }}
                        className={styles.gap16}
                        constant
                        tabIndex={0}
                        onKeyDown={(e: any) => {
                          if (e.keyCode === EnterKeyCode) {
                            setActiveTab(DeathCaseTabs.TabSummary);
                            scroll(250);
                          }
                        }}
                      >
                        <Typography
                          className={styles.lh3}
                          msg={`${deathCase?.firstName} ${deathCase?.lastName}`}
                          tag="div"
                          size="xxxl"
                        />
                        <Typography msg="See details" size="l" tag="div" className={styles.seeDetails} underline />
                      </Row>
                    </Row>

                    <Row size={4} column className={styles.alignSelf}>
                      {user?.businessReferral && (
                        <>
                          <Typography
                            msg={t('label_as_recommended_by' as Messages)}
                            tag="div"
                            size="l"
                            color="footerColor"
                          />
                          <img className={styles.businessReferralLogo} src={user.businessReferral} alt="" />
                        </>
                      )}
                    </Row>
                  </Row>
                )}

                <Row size={8} className={classNames(styles.bottomSpacing, styles.rightSideGap, styles.shortenWidth)}>
                  <Typography msg={t('label_notification_manager' as Messages)} tag="div" size="l" />
                </Row>

                {user?.appVersion !== AppVersion.second &&
                  data?.deathCase.myPermission !== Permissions.ReadOnly &&
                  missingItems &&
                  missingItems.length > 0 && (
                    <div className={styles.gapping}>
                      <Row
                        size={isTablet ? 10 : 8}
                        className={classNames(styles.missingInfoBox, styles.rightSideGap, styles.shortenWidth)}
                      >
                        <Col size={4}>
                          <Row constant>
                            <Col className={styles.infoIcon}>
                              <Icon icon="info" />
                            </Col>
                            <Col size={12}>
                              <Row>
                                <Col>
                                  <Typography msg="To do before sending notifications" tag="div" size="s" bolder />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                        <Col size={2} />
                        <Col size={5}>
                          <Row className={styles.mobileMissingItems} column>
                            {missingItems &&
                              missingItems.map((item: any, idx: number) => (
                                <Col key={idx}>
                                  <Icon
                                    className={classNames(styles.iconPlacement, styles.missingItems)}
                                    icon={chooseIcon(item)}
                                    size={IconSizes.ss}
                                  />
                                  <span
                                    className={styles.handSymbol}
                                    onClick={() => chooseLink(item)}
                                    //eslint-disable-next-line
                                    tabIndex={0}
                                    onKeyDown={(e: any) => {
                                      if (e.keyCode === EnterKeyCode) {
                                        chooseLink(item);
                                      }
                                    }}
                                  >
                                    <Typography
                                      className={styles.sent}
                                      msg={getIdLabels(item)}
                                      tag="span"
                                      bold
                                      color="red"
                                      size="s"
                                      underline={true}
                                    />
                                  </span>
                                </Col>
                              ))}
                          </Row>
                        </Col>

                        <Col />
                      </Row>
                    </div>
                  )}

                {!loading && !notificationCounts.loading && (
                  <div ref={overlayEl} className={classNames(styles.container, styles.rightSideGap)}>
                    <Tabs
                      tabs={dcTabs}
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      className={styles.tabSpacing}
                      tabChangeEvent={() => setShowUserNotification(false)}
                    />
                  </div>
                )}
              </div>
              {!loading && !notificationCounts.loading && (
                <div>
                  {activeTab === DeathCaseTabs.TabServices && (
                    <ServicesPage
                      caseData={deathCase as DeathCaseFragment}
                      goToTab={goToTab}
                      notificationsCount={
                        notificationCounts.data?.totalNotificationsByStatus as CaseServiceProviderStatisticsNode
                      }
                      notificationsNumber={sumNotifications}
                    />
                  )}

                  {activeTab === DeathCaseTabs.TabDocuments && (
                    <Col className={styles.res1400Max} size={isTablet ? 12 : 10}>
                      <DocumentsPage caseData={deathCase as DeathCaseFragment} />
                    </Col>
                  )}
                  {activeTab === DeathCaseTabs.TabCollaborators && (
                    <Col className={styles.res1400Max} size={isTablet ? 12 : 10}>
                      <CollaboratorsPage caseData={deathCase as DeathCaseFragment} />
                    </Col>
                  )}
                  {activeTab === DeathCaseTabs.TabSummary && (
                    <Col
                      size={isMobileBetweenTablet ? 11 : otherRightDivSize}
                      constant
                      className={classNames(styles.summaryContainer, styles.res1400SummaryMax)}
                    >
                      <SummaryInfo data={dcItems} sectionsToCol />
                    </Col>
                  )}
                </div>
              )}

              {(loading || notificationCounts.loading) && (
                <Col size={8} alignCenter textCenter>
                  <LoadingSpinner />
                </Col>
              )}
              {!isTablet && <MiniFooter />}
            </Col>
          </div>
        )}
      </main>

      {showUserNotification && deathCaseNotificationHandler(dispatch, caseproviderid, false, data?.deathCase, true)}
    </>
  );
};

export default ReadyDCPage;
