import classNames from 'classnames';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';

import Icon from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { NotificationActions } from '@Routes/deathCase/DeathCasePage/NotificationsForm/NotificationsForm';

import styles from './CautionBox.scss';

export interface CautionBoxProps {
  title?: string;
  info: string;
  containerClassName: string;
  showMidSection?: boolean;
  id?: string;
  serviceProviderId?: string;
  setNextSp?: (stepAction: NotificationActions) => void;
  isForm?: boolean;
}

const CautionBox: React.FunctionComponent<CautionBoxProps> = ({
  title,
  info,
  containerClassName,
  id,
  serviceProviderId,
  setNextSp,
  isForm,
}) => {
  return (
    <Row constant id={id} className={classNames(styles.infoBox, containerClassName)}>
      <Col>
        <Row constant>
          <Col className={styles.infoIcon}>
            <Icon icon="info" />
          </Col>
          {title && (
            <Col size={12}>
              <Typography className={styles.title} msg={title} tag="div" size="s" bolder />
            </Col>
          )}
        </Row>
      </Col>

      <Col>
        {!info.includes('null') && <p className={styles.fs14}>{ReactHtmlParser(info)}</p>}
        {serviceProviderId && isForm && (
          <>
            <span className={styles.fs14}>If you do not have account information, you can</span>
            &nbsp;
            <span className={styles.linkButton} onClick={() => setNextSp && setNextSp(NotificationActions.Skipped)}>
              skip this company for now
            </span>
          </>
        )}
      </Col>
    </Row>
  );
};

export default CautionBox;
