import { ExecutionResult, getOperationName } from 'apollo-link';

import { queryCollaboratorsList } from '@Graphql/collaborators/queries';
import {
  mutationCreateCollaborator,
  mutationCreateDC,
  mutationDeleteCollaborator,
  mutationDeleteDocuments,
  mutationSendAll,
  mutationSendOne,
  mutationUpdateCollaborator,
  mutationUpdateDC,
  mutationUploadDocuments,
} from '@Graphql/dc/mutations';
import { queryDC, queryDCList, queryTotalNotificationsByStatus } from '@Graphql/dc/queries';
import ApolloClient from '@Graphql/graphqlClient';
import {
  CreateCollaboratorInput,
  CreateCollaboratorMutation,
  CreateCollaboratorMutationVariables,
  DeathCaseCreateMutation,
  DeathCaseCreateMutationInput,
  DeathCaseCreateMutationVariables,
  DeathCaseProviderSendAllMutation,
  DeathCaseProviderSendAllMutationInput,
  DeathCaseProviderSendAllMutationVariables,
  DeathCaseProviderSendOneMutation,
  DeathCaseProviderSendOneMutationInput,
  DeathCaseProviderSendOneMutationVariables,
  DeathCaseUpdateMutation,
  DeathCaseUpdateMutationInput,
  DeathCaseUpdateMutationVariables,
  DeleteCollaboratorInput,
  DeleteCollaboratorMutation,
  DeleteCollaboratorMutationVariables,
  DeleteDocumentMutation,
  UpdateCollaboratorInput,
  UpdateCollaboratorMutation,
  UpdateCollaboratorMutationVariables,
  UploadDocumentMutation,
} from '@Graphql/graphqlTypes.generated';
import { queryLC } from '@Graphql/lc/queries';
import { queryCaseServiceProviders, queryUserServiceProviderNotifications } from '@Graphql/providers/queries';

export const createDCMutation = async (input: DeathCaseCreateMutationInput) => {
  const result: ExecutionResult<DeathCaseCreateMutation> = await ApolloClient.mutate<
    DeathCaseCreateMutation,
    DeathCaseCreateMutationVariables
  >({
    mutation: mutationCreateDC,
    variables: {
      input,
    },
  });

  return result.data?.deathCaseCreate;
};

export const uploadDocumentsMutation = async (input: any) => {
  const result: ExecutionResult<UploadDocumentMutation> = await ApolloClient.mutate<any, any>({
    mutation: mutationUploadDocuments,
    variables: {
      input,
    },
    refetchQueries: [getOperationName(queryDC) || ''],
  });

  return result.data?.uploadDocument;
};

export const deleteDocumentsMutation = async (input: any, isLifeCase: boolean) => {
  const result: ExecutionResult<DeleteDocumentMutation> = await ApolloClient.mutate<any, any>({
    mutation: mutationDeleteDocuments,
    refetchQueries: [getOperationName(isLifeCase ? queryLC : queryDC) || ''],
    variables: {
      input,
    },
  });

  return result.data?.deleteDocument;
};

export const updateDCMutation = async (input: DeathCaseUpdateMutationInput) => {
  const result: ExecutionResult<DeathCaseUpdateMutation> = await ApolloClient.mutate<
    DeathCaseUpdateMutation,
    DeathCaseUpdateMutationVariables
  >({
    mutation: mutationUpdateDC,
    variables: {
      input,
    },
  });

  return result.data?.deathCaseUpdate;
};

export const createCollaboratorMutation = async (input: CreateCollaboratorInput, isProfessionalPortal?: boolean) => {
  const result: ExecutionResult<CreateCollaboratorMutation> = await ApolloClient.mutate<
    CreateCollaboratorMutation,
    CreateCollaboratorMutationVariables
  >({
    mutation: mutationCreateCollaborator,
    variables: {
      input,
    },
    refetchQueries: [getOperationName(isProfessionalPortal ? queryDCList : queryCollaboratorsList) || ''],
  });

  return result.data?.createCollaborator;
};

export const updateCollaboratorMutation = async (input: UpdateCollaboratorInput) => {
  const result: ExecutionResult<UpdateCollaboratorMutation> = await ApolloClient.mutate<
    UpdateCollaboratorMutation,
    UpdateCollaboratorMutationVariables
  >({
    mutation: mutationUpdateCollaborator,
    variables: {
      input,
    },
    refetchQueries: [getOperationName(queryDC) || ''],
  });

  return result.data?.updateCollaborator;
};

export const updatePermissionCollaboratorMutation = async (input: UpdateCollaboratorInput) => {
  const result: ExecutionResult<UpdateCollaboratorMutation> = await ApolloClient.mutate<
    UpdateCollaboratorMutation,
    UpdateCollaboratorMutationVariables
  >({
    mutation: mutationUpdateCollaborator,
    variables: {
      input,
    },
  });

  return result.data?.updateCollaborator;
};

export const removeCollaboratorMutation = async (input: DeleteCollaboratorInput) => {
  const result: ExecutionResult<DeleteCollaboratorMutation> = await ApolloClient.mutate<
    DeleteCollaboratorMutation,
    DeleteCollaboratorMutationVariables
  >({
    mutation: mutationDeleteCollaborator,
    variables: {
      input,
    },
  });

  return result.data?.deleteCollaborator;
};

export const sendOneMutation = async (input: DeathCaseProviderSendOneMutationInput) => {
  const result: ExecutionResult<DeathCaseProviderSendOneMutation> = await ApolloClient.mutate<
    DeathCaseProviderSendOneMutation,
    DeathCaseProviderSendOneMutationVariables
  >({
    mutation: mutationSendOne,
    variables: {
      input,
    },
    refetchQueries: [
      getOperationName(queryCaseServiceProviders) || '',
      getOperationName(queryDC) || '',
      getOperationName(queryTotalNotificationsByStatus) || '',
      getOperationName(queryUserServiceProviderNotifications) || '',
    ],
  });

  return result.data?.deathCaseProviderSendOne;
};

export const sendAllMutation = async (input: DeathCaseProviderSendAllMutationInput) => {
  const result: ExecutionResult<DeathCaseProviderSendAllMutation> = await ApolloClient.mutate<
    DeathCaseProviderSendAllMutation,
    DeathCaseProviderSendAllMutationVariables
  >({
    mutation: mutationSendAll,
    variables: {
      input,
    },
    refetchQueries: [getOperationName(queryCaseServiceProviders) || ''],
  });

  return result.data?.deathCaseProviderSendAll;
};
