import classNames from 'classnames';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router';

import LandingNewsLetters from '@Components/application/NewsLettersLanding/LandingNewsLetters';
import Button from '@Components/Button';
import LandingFooter from '@Components/FooterLanding/LandingFooter';
import Icon, { IconSizes } from '@Components/Icon';
import Col from '@Components/layout/Col';
import Row from '@Components/layout/Row';
import Typography from '@Components/Typography';
import { Links, ReactResponsiveQueries } from '@Config/constants';
import { Messages } from '@Config/messages';
import { UserLandingPage } from '@Graphql/graphqlTypes.generated';
import { useTranslations } from '@Hooks/useTranslations';

import BeSpokeLoginForm from '../BeSpokeLoginPage/BeSpokeLoginForm';

import styles from './NewRegisterPage.scss';

export interface NewRegisterPageProps {}

const NewRegisterPage: React.FunctionComponent<NewRegisterPageProps> = () => {
  const t = useTranslations();
  const isTablet = useMediaQuery({ query: ReactResponsiveQueries.Tablet });
  const location = useLocation();
  const isLifeCase = location.pathname === Links.retargetLifeRegistration;

  const landingPage = isLifeCase ? UserLandingPage.RetargetLifeCase : UserLandingPage.RetargetDeathCase;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling animation
    });
  };

  return (
    <>
      <section className={styles.mainContentLayout}>
        <Row size={12} className={styles.gapBetween}>
          <Col className={styles.infoContent}>
            <img
              width={!isTablet ? '297' : '161'}
              height={!isTablet ? '63' : '35'}
              src="https://lifeledger.com/wp-content/uploads/2021/08/Black.svg"
              alt=""
            />
            <Typography
              className={classNames(styles.w68, styles.mb8)}
              family="Montserrat"
              msg={isLifeCase ? t('label_signup_for_free' as Messages) : t('label_dc_signup_for_free' as Messages)}
              tag="div"
              html
            />
            <Typography
              msg={isLifeCase ? t('label_register_&_notify_1000+' as Messages) : t('label_ll_dc_info' as Messages)}
              tag="div"
              size="xl"
              className={classNames(styles.w68, styles.closeFreeze)}
              html
            />
          </Col>
          <Col constant>
            <BeSpokeLoginForm landingPage={landingPage} />
          </Col>
          <Button onClick={() => scrollToTop()} className={classNames(styles.rotateButton)}>
            <Typography msg={t('button_signup_free' as Messages)} tag="div" size="l" html />
          </Button>
        </Row>
      </section>
      <section>
        <LandingNewsLetters />
      </section>
      <section className={styles.statsContentLayout}>
        <Row size={12} className={styles.statsGap}>
          <Col className={styles.statsContent} size={5}>
            <Typography
              msg={t('label_key_statistics' as Messages)}
              tag="div"
              family="Montserrat"
              className={classNames(styles.fontStyle)}
              html
            />
            <Row className={styles.iconStyle}>
              <Col className={styles.pt12}>
                <Icon icon="notify" size={IconSizes.statsIcon} />
              </Col>
              <Col>
                <Typography
                  msg={t('label_notifications_sent_num' as Messages)}
                  tag="div"
                  className={classNames(styles.iconFontStyle)}
                  html
                  bolder
                />
                <Typography
                  msg={t('label_notifications_sent_msg' as Messages)}
                  tag="div"
                  size="xl"
                  className={classNames(styles.iconTextStyle)}
                />
              </Col>
            </Row>
            <div className={styles.divider} />
            <Row className={styles.iconStyle}>
              <Col className={styles.pt12}>
                <Icon icon="family" size={IconSizes.statsIcon} />
              </Col>
              <Col>
                <Typography
                  msg={t('label_families_helped_num' as Messages)}
                  tag="div"
                  className={classNames(styles.iconFontStyle)}
                  html
                  bolder
                />
                <Typography
                  msg={t('label_families_helped_msg' as Messages)}
                  tag="div"
                  size="xl"
                  className={classNames(styles.iconTextStyle)}
                />
              </Col>
            </Row>
            <div className={styles.divider} />
            <Row className={styles.iconStyle}>
              <Col className={styles.pt12}>
                <Icon icon="build" size={IconSizes.statsIcon} />
              </Col>
              <Col>
                <Typography
                  msg={t('label_registered_companies_num' as Messages)}
                  tag="div"
                  className={classNames(styles.iconFontStyle)}
                  html
                  bolder
                />
                <Typography
                  msg={t('label_registered_companies_msg' as Messages)}
                  tag="div"
                  size="xl"
                  className={classNames(styles.iconTextStyle)}
                />
              </Col>
            </Row>
            <div className={classNames(styles.divider, styles.mt30)} />
          </Col>
          <Col className={styles.tipsContent} size={8}>
            <Typography
              msg={
                isLifeCase
                  ? t('label_life_registration_service' as Messages)
                  : t('label_dc_registration_service' as Messages)
              }
              tag="div"
              family="Montserrat"
              className={classNames(styles.tipsStyle)}
              html
            />
            <Row constant className={styles.mt35}>
              <Col>
                <Icon icon="ticky" size={IconSizes.s26} className={styles.mt5} />
              </Col>
              <Col>
                <Typography
                  msg={isLifeCase ? t('label_inform_over_1000+' as Messages) : t('label_dc_benefits_one' as Messages)}
                  tag="div"
                  size="xl"
                  className={classNames(styles.tipsContentStyle)}
                />
              </Col>
            </Row>
            <Row constant className={styles.mt20}>
              <Col>
                <Icon icon="ticky" size={IconSizes.s26} className={styles.mt5} />
              </Col>
              <Col>
                <Typography
                  msg={
                    isLifeCase ? t('label_simplify_administrative' as Messages) : t('label_dc_benefits_two' as Messages)
                  }
                  tag="div"
                  size="xl"
                  className={classNames(styles.tipsContentStyle)}
                />
              </Col>
            </Row>
            <Row constant className={styles.mt20}>
              <Col>
                <Icon icon="ticky" size={IconSizes.s26} className={styles.mt5} />
              </Col>
              <Col>
                <Typography
                  msg={isLifeCase ? t('label_manage_everything' as Messages) : t('label_dc_benefits_three' as Messages)}
                  tag="div"
                  size="xl"
                  className={classNames(styles.tipsContentStyle)}
                />
              </Col>
            </Row>
            <Row constant className={styles.mt20}>
              <Col>
                <Icon icon="ticky" size={IconSizes.s26} className={styles.mt5} />
              </Col>
              <Col>
                <Typography
                  msg={isLifeCase ? t('label_easy_to_use' as Messages) : t('label_dc_benefits_four' as Messages)}
                  tag="div"
                  size="xl"
                  className={classNames(styles.tipsContentStyle)}
                />
              </Col>
            </Row>
            <Row constant className={styles.mt20}>
              <Col>
                <Icon icon="ticky" size={IconSizes.s26} className={styles.mt5} />
              </Col>
              <Col>
                <Typography
                  msg={isLifeCase ? t('label_reduce_stress' as Messages) : t('label_dc_benefits_five' as Messages)}
                  tag="div"
                  size="xl"
                  className={classNames(styles.tipsContentStyle)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
      <section className={styles.whiteContentLayout}>
        <Typography
          msg={isLifeCase ? t('label_how_it_works' as Messages) : t('label_dc_how_it_works' as Messages)}
          tag="div"
          family="Montserrat"
          className={classNames(styles.workFonts)}
          html
        />
        <Row size={12} justifyCenter className={styles.gap65}>
          <Col size={2}>
            <Row size={12} justifyCenter constant>
              <div className={styles.circle}>
                <Icon icon="monitor" size={IconSizes.l78} />
              </div>
            </Row>
            <Row size={12} justifyCenter constant>
              <div className={styles.rectangle}>
                <div className={styles.smallRectangle}>
                  <Typography msg={t('label_step1' as Messages)} tag="div" size="s" />
                </div>
              </div>
            </Row>
            <Typography
              msg={isLifeCase ? t('label_create_free_account' as Messages) : t('label_create_dc_account' as Messages)}
              tag="div"
              size="l"
              className={classNames(styles.circleFonts)}
              html
            />
          </Col>
          <Col size={2}>
            <Row size={12} justifyCenter constant>
              <div className={styles.circle}>
                <Icon icon="accounts" size={IconSizes.l78} />
              </div>
            </Row>
            <Row size={12} justifyCenter constant>
              <div className={styles.rectangle}>
                <div className={styles.smallRectangle}>
                  <Typography msg={t('label_step2' as Messages)} tag="div" size="s" />
                </div>
              </div>
            </Row>
            <Typography
              msg={isLifeCase ? t('label_add_people' as Messages) : t('label_add_dc' as Messages)}
              tag="div"
              size="l"
              className={classNames(styles.circleFonts)}
              html
            />
          </Col>
          <Col size={2}>
            <Row size={12} justifyCenter constant>
              <div className={styles.circle}>
                <Icon icon="select" size={IconSizes.l78} />
              </div>
            </Row>
            <Row size={12} justifyCenter constant>
              <div className={styles.rectangle}>
                <div className={styles.smallRectangle}>
                  <Typography msg={t('label_step3' as Messages)} tag="div" size="s" />
                </div>
              </div>
            </Row>
            <Typography
              msg={isLifeCase ? t('label_notify_companies' as Messages) : t('label_dc_notify_companies' as Messages)}
              tag="div"
              size="l"
              className={classNames(styles.circleFonts)}
              html
            />
          </Col>
        </Row>
        <Row size={12} constant justifyCenter className={styles.mt56}>
          <Button onClick={() => scrollToTop()} className={styles.signupRect}>
            <Typography msg={t('button_signup_free' as Messages)} tag="div" size="l" html />
          </Button>
        </Row>
        <Row size={12} constant justifyCenter>
          <div className={classNames(styles.lightDivider, styles.mb80)} />
        </Row>
        <Row size={12} justifyCenter className={styles.ml10}>
          <Row size={6}>
            <Typography
              msg={t('label_partners' as Messages)}
              tag="div"
              family="Montserrat"
              className={classNames(styles.partnerFonts)}
              html
            />
          </Row>
        </Row>
        <Row wrap constant size={12} className={styles.p95}>
          <div className={styles.companyIcons}>
            <Icon icon="bgl" size={IconSizes.britishGasIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="bt" size={IconSizes.l78} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="anglian-icon" size={IconSizes.anglianIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="aa-icon" size={IconSizes.aaIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="hml" size={IconSizes.homeIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="tsbl" size={IconSizes.tsbIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="originl" size={IconSizes.originIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="swl" size={IconSizes.swIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="swwl" size={IconSizes.swwIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="sswl" size={IconSizes.sswIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="hdl" size={IconSizes.hdIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="one-call" size={IconSizes.oneCallicon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="uul" size={IconSizes.uuIcon} />
          </div>
          <div className={styles.companyIcons}>
            <Icon icon="utilital" size={IconSizes.utilitaIcon} />
          </div>
          <div className={classNames(styles.companyIcons)}>
            <Icon icon="o2l" size={IconSizes.m68} />
          </div>
        </Row>
        <Row size={12} constant justifyCenter>
          <div className={styles.lightDivider} />
        </Row>
      </section>
      <section className={styles.commentSectionLayout}>
        <Row size={12}>
          <Col size={6} className={styles.pl11}>
            <div className={styles.commentSection}>
              <div className={styles.whiteComment}>
                <Icon icon="yellow-quotesl" size={IconSizes.yellowQuotes} />
                <Typography
                  msg={t('title_customer_feedback' as Messages)}
                  tag="div"
                  family="Montserrat"
                  bold
                  className={styles.whiteHeaderFonts}
                />
                <Typography
                  msg={t('label_customer_feedback' as Messages)}
                  tag="div"
                  family="Montserrat"
                  className={styles.lh34}
                />
                <Row constant size={12} className={styles.gap3}>
                  <Icon icon="yellow-starl" size={IconSizes.s24} />
                  <Icon icon="yellow-starl" size={IconSizes.s24} />
                  <Icon icon="yellow-starl" size={IconSizes.s24} />
                  <Icon icon="yellow-starl" size={IconSizes.s24} />
                  <Icon icon="yellow-starl" size={IconSizes.s24} />
                </Row>
                <Row constant size={12} className={styles.gap15}>
                  <Col>
                    <div className={styles.smallCircle}>
                      <Typography msg="JL" tag="div" family="Montserrat" bolder className={styles.ft30} />
                    </div>
                  </Col>
                  <Col>
                    <Typography msg="Joe Lander" tag="div" bold className={styles.userFonts} />
                    <Typography
                      msg="28 September 2022"
                      tag="div"
                      bold
                      className={classNames(styles.userFonts, styles.textColor)}
                    />
                    <div className={styles.flexDisplay}>
                      <Icon icon="small-tickl" size={IconSizes.sxs} />
                      <Typography msg="Verified by us" tag="div" bold className={classNames(styles.verifyFonts)} />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col size={6}>
            <div className={styles.mt70}>
              <Icon icon="double-quotesl" size={IconSizes.doubleQuotesIcon} />
            </div>
            <Typography
              msg={t('label_virginmedia_quote' as Messages)}
              tag="div"
              family="Montserrat"
              className={classNames(styles.commentFonts)}
              html
            />
            <div className={styles.virginPos}>
              <Icon icon="virgin-medial" size={IconSizes.virginMobile} />
            </div>
          </Col>
        </Row>
      </section>
      <section className={styles.footer}>
        <LandingFooter />
      </section>
    </>
  );
};

export default NewRegisterPage;
